import React from 'react';

const Whatsapp = props => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <path
      fill={props.fill || '#fff'}
      d="M17 2.906a9.914 9.914 0 00-15.6 11.96L0 20l5.254-1.379a9.881 9.881 0 004.737 1.205 10.006 10.006 0 0010-9.911 9.95 9.95 0 00-3-7.009zm-7 15.25a8.223 8.223 0 01-4.2-1.147l-.3-.179-3.116.82.83-3.04-.2-.312a8.253 8.253 0 1115.308-4.379A8.329 8.329 0 0110 18.156zm4.518-6.17c-.246-.125-1.464-.723-1.692-.8s-.393-.125-.558.125-.638.8-.786.973-.29.188-.536.063a6.741 6.741 0 01-3.371-2.946c-.254-.437.254-.406.728-1.353a.459.459 0 00-.022-.433c-.062-.125-.558-1.344-.763-1.839s-.406-.415-.558-.424-.308-.009-.473-.009a.917.917 0 00-.661.308 2.783 2.783 0 00-.866 2.067 4.851 4.851 0 001.009 2.563 11.066 11.066 0 004.231 3.737 4.846 4.846 0 002.973.621 2.536 2.536 0 001.67-1.179 2.071 2.071 0 00.143-1.179c-.062-.111-.227-.174-.473-.294z"
    />
  </svg>
);

export default Whatsapp;
