export const PayloadCrm  = (form, product,id_channel) => {

    const {
        email,
        fixedPhone,
        documentNumber,
        name,
        city,
        state,
        razaoSocial
    } = form;

    
    const corporateLeadData = {
        worklogs:[{
              restrictionId:1,
              text:"Lead gerada pela integração Sensedia AlgarCRM"
        }],
        fields:[
            {
               fieldName:"URL_PAGE_CURRENT",
               stringFieldValue: window.location.href,
               fieldType:"S"
            },
         ],
        leadType:{
            id:3
        },
        requestChannel:{
            id:5
        },
        campaign: { 
          id: id_channel
        },
        contactEmail:email,
        contactPhoneNumber:fixedPhone,
        contactName:name,
        customer:{
            primaryDocumentNumber:documentNumber.match(/\d/g).join(""),
            email:email,
            name:razaoSocial
        },
        address: {
            state: {
                name:state
            },
            localityName: city,
            countyName: city,
            streetNumber:0,
            neighbourhood: { 
                name: "Não informado"
            },
    
         },
        originIntegrationId: process.env.INTEGRATION_ID,
        originIntegrationType: process.env.INTEGRATION_TYPE,
    };


    if(product){
        corporateLeadData.fields.push({
            fieldName: 'PRODUCT_DESCRIPTION',
            stringFieldValue:product,
            fieldType:"S"
        });
    }


    if(document.referrer != ''){
        corporateLeadData.fields.push({
            fieldName:"URL_PAGE_BEFORE",
            stringFieldValue: document.referrer,
            fieldType:"S"
        });
    }

    return corporateLeadData;
}