import * as React from "react"

function SvgYoutube(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <defs>
        <path
          id="youtube_svg__a"
          d="M16 2.667c7.363 0 13.333 5.97 13.333 13.333S23.363 29.333 16 29.333 2.667 23.363 2.667 16 8.637 2.667 16 2.667zm.277 7.5h-.554c-1.096.006-5.12.047-6.243.343a2.13 2.13 0 00-1.468 1.482c-.319 1.211-.342 3.629-.343 3.968v.08c.001.341.024 2.77.343 3.968a2.088 2.088 0 001.468 1.468c1.172.318 5.412.353 6.34.357h.36c.93-.004 5.18-.038 6.34-.343a2.088 2.088 0 001.468-1.468c.307-1.165.34-3.445.343-3.92v-.243c-.003-.593-.037-2.75-.343-3.867a2.088 2.088 0 00-1.468-1.468c-1.123-.308-5.147-.35-6.243-.356zm-1.938 3.335L18.676 16l-4.337 2.498v-4.996z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="youtube_svg__b" fill="#fff">
          <use xlinkHref="#youtube_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#youtube_svg__a" />
        <g fill="#505F79" mask="url(#youtube_svg__b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgYoutube
