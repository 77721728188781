import * as React from "react"

function SvgInstagram(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <defs>
        <path
          id="instagram_svg__a"
          d="M16 2.667c7.363 0 13.333 5.97 13.333 13.333S23.363 29.333 16 29.333 2.667 23.363 2.667 16 8.637 2.667 16 2.667zm.932 5.678h-1.864c-1.255.003-1.55.014-2.225.045-.815.037-1.371.167-1.858.356-.51.192-.974.493-1.356.883-.39.382-.691.845-.883 1.356-.19.487-.319 1.043-.356 1.858-.031.675-.042.97-.045 2.225v1.864c.003 1.255.014 1.55.045 2.225.037.815.167 1.371.356 1.858.192.51.494.974.883 1.356.382.39.845.691 1.356.883.487.19 1.044.319 1.858.356.71.032 1 .043 2.431.046h1.452c1.431-.003 1.72-.014 2.43-.046.816-.037 1.372-.167 1.86-.356a3.917 3.917 0 002.238-2.239c.19-.487.319-1.043.356-1.858.033-.71.043-1 .046-2.43v-1.453c-.003-1.43-.013-1.72-.046-2.43-.037-.816-.166-1.372-.356-1.86a3.742 3.742 0 00-.883-1.355 3.738 3.738 0 00-1.356-.883c-.487-.19-1.043-.319-1.858-.356-.675-.03-.97-.042-2.225-.045zm-.438 1.379c1.597 0 1.863.01 2.6.044.746.034 1.152.159 1.421.264.333.122.634.318.88.572.255.247.45.548.573.88.105.27.23.676.264 1.422.03.667.041.948.044 2.179v1.83a38.795 38.795 0 01-.044 2.18c-.034.746-.159 1.15-.264 1.42a2.535 2.535 0 01-1.453 1.454c-.27.105-.675.23-1.421.263-.807.037-1.05.045-3.094.045h-.257c-1.81 0-2.065-.01-2.837-.045-.746-.034-1.152-.158-1.422-.263a2.383 2.383 0 01-.88-.573 2.383 2.383 0 01-.573-.88c-.104-.27-.23-.675-.263-1.422-.032-.702-.042-.977-.044-2.38v-1.427c.002-1.404.012-1.679.044-2.38.034-.747.159-1.153.263-1.422.123-.333.319-.634.573-.88a2.37 2.37 0 01.88-.573c.27-.105.676-.23 1.422-.264.737-.034 1.003-.043 2.6-.044zM16 12.069a3.932 3.932 0 100 7.864 3.932 3.932 0 000-7.864zm0 1.379a2.551 2.551 0 110 5.104 2.551 2.551 0 110-5.104zm4.087-2.454a.92.92 0 000 1.838.92.92 0 00-.001-1.838z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="instagram_svg__b" fill="#fff">
          <use xlinkHref="#instagram_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#instagram_svg__a" />
        <g fill="#505F79" mask="url(#instagram_svg__b)">
          <path d="M0 0h32v32H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgInstagram
