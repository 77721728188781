import React from 'react';
import styles from 'components/FormLead/form.module.css';
import classnames from "classnames";
import { Icon } from "components/Icons";

const SuccessModal = ({openModal}) => {
    return(
        <section>
            <button
                onClick={() => openModal()} 
                className={classnames('f24 ph12 absolute pointer top-1 right-1 a',styles.button_close)}
            >
                <Icon 
                    name="Fechar" 
                    aria-label="Fechar" 
                    title="Fechar"
                    className={styles.iconClose} 
                />
            </button>
            <div className="ph32 mt32 pb32 flex flex-column  b--black-30">
                <div className="tc">
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="62" viewBox="0 0 56 62"><g fill="none"><path fill="#424242" d="M55.53 41.292a1.25 1.25 0 01-1.03 1.958h-37A5.256 5.256 0 0112.25 38c0-.983.282-1.924.8-2.75H2.75V60a1.25 1.25 0 11-2.5 0V2a1.25 1.25 0 112.5 0v1.75H23.5c.691 0 1.25.559 1.25 1.25v3.75H54.5a1.252 1.252 0 011.03 1.958L45.017 26 55.53 41.292z"></path><path fill="#FFF" d="M2.5 6h20v27h-20z"></path><path fill="#018942" d="M17.5 41c-1.654 0-3-1.346-3-3s1.346-3 3-3h6a1 1 0 001-1V11h28.099l-9.923 14.434a1.002 1.002 0 000 1.133L52.599 41H17.5z"></path></g></svg>
                </div>
            </div>
            <p className="tc fw6 f14">
                Tudo certo!
            </p>
            <p className="f14 lh-copy tc">
                Agora é só aguardar.
            </p>
            <p className="f14 lh-copy tc pb12">
                Em breve a gente entra em contato com você. : )
            </p>
        </section>
    )
}

export default SuccessModal;