import * as React from "react"

function YoutubeRed(props) {
  return (
    <svg width="32px" height="23px" viewBox="0 0 32 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>icon/redesocial/youtube</title>
      <defs>
        <path d="M12.7272727,16.116914 L12.7272727,6.54975269 L21.0909091,11.3335161 L12.7272727,16.116914 Z M31.3312727,3.53947312 C30.9632727,2.1462043 29.8789091,1.04906452 28.5021818,0.676709677 C26.0067273,0 16,0 16,0 C16,0 5.99327273,0 3.49781818,0.676709677 C2.12109091,1.04906452 1.03672727,2.1462043 0.668727273,3.53947312 C0,6.0646129 0,11.3333333 0,11.3333333 C0,11.3333333 0,16.601871 0.668727273,19.1271935 C1.03672727,20.5204624 2.12109091,21.6176022 3.49781818,21.9901398 C5.99327273,22.6666667 16,22.6666667 16,22.6666667 C16,22.6666667 26.0067273,22.6666667 28.5021818,21.9901398 C29.8789091,21.6176022 30.9632727,20.5204624 31.3312727,19.1271935 C32,16.601871 32,11.3333333 32,11.3333333 C32,11.3333333 32,6.0646129 31.3312727,3.53947312 L31.3312727,3.53947312 Z" id="path-4"></path>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="360px---Empresa-digital---Produtos-default" transform="translate(-213.000000, -2274.000000)">
          <g id="Group-5" transform="translate(0.000000, 1889.000000)">
            <g id="color/verde" transform="translate(213.000000, 385.000000)">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-4"></use>
              </mask>
              <use id="Mask" fill="#00A750" xlinkHref="#path-4"></use>
              <g id="Group" mask="url(#mask-4)">
                <g transform="translate(-16.000000, -21.000000)">
                  <rect id="Rectangle" fill="#D9252A" x="0" y="0" width="56" height="56"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default YoutubeRed;
