
const StringUtils = {
    normalizeUpper : str => {
        str = str.replace(/[ÀÁÂÃÄÅ]/g, "A")
        str = str.replace(/[àáâãäå]/g, "a")
        str = str.replace(/[ÈÉÊË]/g, "E")
        str = str.replace(/[èéêë]/g, "e")
        str = str.replace(/[ÌÍÎÏ]/g, "I")
        str = str.replace(/[ìíîï]/g, "i")
        str = str.replace(/[ÒÓÕÔÖ]/g, "O")
        str = str.replace(/[òóõôö]/g, "o")
        str = str.replace(/[ÙÚÛÜ]/g, "U")
        str = str.replace(/[ùúûü]/g, "u")
        str = str.replace(/[ç]/g, "c")
        str = str.replace(/[Ç]/g, "C")

        str =  str.replace(/[^a-z0-9]/gi, "")
        return str.toUpperCase()

    },

    getCPF : strCPF => {
        strCPF = strCPF.replace(/[^\d]+/g,'');
        var soma;
        var resto;
        soma = 0;
        if (strCPF == "00000000000") return false;

        for (var i=1; i<=9; i++) soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11))  resto = 0;
        if (resto != parseInt(strCPF.substring(9, 10)) ) return "";

        soma = 0;
        for (var i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11))  resto = 0;
        if (resto != parseInt(strCPF.substring(10, 11) ) ) return "";
        return strCPF;
    },

    getCNPJ : cnpj => {
        console.log("o cnpj informado foi ", cnpj);

        cnpj = cnpj.replace(/[^\d]+/g,'');
        if(cnpj === '') return false;

        if (cnpj.length !== 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return false;


        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        //console.log("O resultado é " + resultado + "ja o digito 1 é ", digitos.charAt(0));

        if (resultado != digitos.charAt(1))
              return "";


        return cnpj
    },

    validateEmail: function (email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    validateCPFCNPJ: function(document){

        if (!document) return false;

        document = document.replace(/\D/g, '');

        if(document.length === 11)
            return this.getCPF(document);

        return this.getCNPJ(document);
    }
}

export default StringUtils
