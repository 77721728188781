import * as React from "react"

function SvgMenu(props) {
  return (
    <svg width={22} height={21} viewBox="0 0 22 21" {...props}>
      <defs>
        <path
          id="menu_svg__a"
          d="M8.675 13.967a1.581 1.581 0 01.001 3.163H3.29a1.582 1.582 0 110-3.162h5.384zm4.274-5.27a1.58 1.58 0 010 3.162H3.29a1.581 1.581 0 110-3.162h9.658zm4.273-5.271a1.581 1.581 0 01.001 3.162H3.29a1.581 1.581 0 110-3.162h13.931z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(.744 .222)">
        <mask id="menu_svg__b" fill="#fff">
          <use xlinkHref="#menu_svg__a" />
        </mask>
        <use fill="#0F447E" xlinkHref="#menu_svg__a" />
        <g fill="#0F447E" mask="url(#menu_svg__b)">
          <path d="M0 0h20.513v20.556H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgMenu
