import * as React from "react"

function SvgLinkedinIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M24 8c8.8 0 16 7.2 16 16s-7.2 16-16 16S8 32.8 8 24 15.2 8 24 8zm-5 25V20.5h-4.2V33H19zm15 0v-7.2c0-3.8-2-5.6-4.8-5.6-2.2 0-3.2 1.2-3.7 2.1v-1.8h-4.2c.1 1.2 0 12.5 0 12.5h4.2v-7c0-.4 0-.7.1-1 .3-.7 1-1.5 2.1-1.5 1.5 0 2.1 1.1 2.1 2.8V33H34zM16.9 14.5c-1.4 0-2.3.9-2.3 2.2 0 1.2.9 2.2 2.3 2.2s2.3-1 2.3-2.2c0-1.3-.8-2.2-2.3-2.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgLinkedinIcon
