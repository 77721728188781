import * as React from "react"

function SvgProtection(props) {
  return (
    <svg width={48} height={45} viewBox="0 0 48 45" {...props}>
      <g data-name="Group 46" opacity={0.899}>
        <g data-name="Group 45">
          <path
            d="M24.001 40.542a2.124 2.124 0 002.124-2.123V36.95a3.065 3.065 0 10-4.247 0v1.469a2.13 2.13 0 002.123 2.123zm0-7.594a1.789 1.789 0 011.1 3.2.64.64 0 00-.246.5v1.758a.85.85 0 01-1.7 0v-1.758a.64.64 0 00-.246-.5 1.789 1.789 0 011.092-3.2z"
            data-name="Path 210"
            fill="#00a750"
          />
          <path
            d="M47.378 22.127a.716.716 0 00-.778.661 9.566 9.566 0 01-9.271 8.779H35v-4.695a2.33 2.33 0 00-2.25-2.4h-1.055v-1.464a7.94 7.94 0 00-7.659-8.178 7.94 7.94 0 00-7.659 8.178v1.462h-1.059a2.331 2.331 0 00-2.25 2.4v4.695h-2.325c-5.147 0-9.337-4.475-9.337-9.97a9.92 9.92 0 016.778-9.58.721.721 0 00.506-.7 5.358 5.358 0 016.9-5.265.68.68 0 00.787-.29 9.01 9.01 0 0115.318 0 .693.693 0 00.787.29 5.358 5.358 0 016.9 5.265.744.744 0 00.506.7 9.683 9.683 0 016.571 7.523.707.707 0 00.834.581.754.754 0 00.544-.891 11.2 11.2 0 00-7.087-8.49 6.786 6.786 0 00-8.2-6.256 10.337 10.337 0 00-17.033 0 6.785 6.785 0 00-8.2 6.246A11.439 11.439 0 000 21.596c0 6.327 4.818 11.472 10.743 11.472h2.325v9.53a2.33 2.33 0 002.25 2.4H22.4a.752.752 0 000-1.5h-7.082a.87.87 0 01-.844-.9V26.882a.876.876 0 01.844-.9h17.436a.876.876 0 01.844.9v15.716a.87.87 0 01-.844.9h-7.078a.752.752 0 000 1.5h7.078a2.331 2.331 0 002.246-2.4v-9.53h2.325A11.024 11.024 0 0048 22.968a.76.76 0 00-.622-.841zm-17.09 2.342H17.783v-1.461a6.48 6.48 0 016.253-6.677 6.48 6.48 0 016.253 6.677v1.462z"
            data-name="Path 211"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgProtection
