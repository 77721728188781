import * as React from "react"

function SvgEconomy(props) {
  return (
    <svg width={44.343} height={44.074} viewBox="0 0 44.343 44.074" {...props}>
      <g
        opacity={0.901}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          d="M1.019 34.086l7.7-13.987 11.3 16 14.46-28.648 4.256 6.7"
          data-name="Path 177"
          stroke="#00a750"
        />
        <path d="M1.019.75v42.574h42.574" data-name="Path 178" stroke="#fff" />
      </g>
    </svg>
  )
}

export default SvgEconomy
