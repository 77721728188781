import * as React from "react"

function SvgCalendar(props) {
  return (
    <svg width={43} height={40} viewBox="0 0 43 40" {...props}>
      <defs>
        <path
          id="calendar_svg__a"
          d="M29.961 1.53a1.08 1.08 0 011.072 1.089v1.074h7.025a1.08 1.08 0 011.072 1.089v24.604a1.08 1.08 0 01-1.072 1.089h-6.58c-1.055 4.576-5.102 7.995-9.924 7.995-4.822 0-8.87-3.419-9.924-7.995H5.05a1.08 1.08 0 01-1.072-1.089V4.782c0-.601.48-1.089 1.072-1.089h7.29V2.619c0-.601.48-1.089 1.073-1.089a1.08 1.08 0 011.072 1.089v1.074h5.997V2.619c0-.601.48-1.089 1.072-1.089a1.08 1.08 0 011.072 1.089v1.074h6.263V2.619c0-.601.48-1.089 1.072-1.089zm-8.407 18.41c-4.439 0-8.05 3.667-8.05 8.176 0 4.508 3.611 8.176 8.05 8.176s8.05-3.668 8.05-8.176c0-4.509-3.611-8.177-8.05-8.177zm-.054 2.137c.594 0 1.076.486 1.076 1.087v5.259l2.157 2.18c.42.425.42 1.113 0 1.538a1.069 1.069 0 01-1.521 0l-2.472-2.5a1.09 1.09 0 01-.315-.768v-5.71c0-.6.482-1.086 1.075-1.086zm15.486-9.575H6.12v15.795h5.243c0-.06-.005-.12-.005-.181 0-5.71 4.574-10.355 10.195-10.355 5.62 0 10.194 4.645 10.194 10.355l-.004.181h5.242V12.502zM12.34 5.87H6.122v4.453h30.864V5.87h-5.953v.984a1.08 1.08 0 01-1.072 1.089 1.081 1.081 0 01-1.072-1.09V5.87h-6.263v.984a1.08 1.08 0 01-1.072 1.089 1.081 1.081 0 01-1.072-1.09V5.87h-5.997v.984a1.08 1.08 0 01-1.072 1.089 1.082 1.082 0 01-1.073-1.09V5.87z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse
          cx={27.681}
          cy={15.246}
          fill="#FBBD0C"
          rx={14.996}
          ry={15.246}
        />
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#calendar_svg__a" />
      </g>
    </svg>
  )
}

export default SvgCalendar
