import * as React from "react"

function Twitter(props) {
  return (
    <svg width="32px" height="29px" viewBox="0 0 32 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>icon/redesocial/twitter</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="360px---Empresa-digital---Produtos-default" transform="translate(-109.000000, -2271.000000)" fill="#1DA1F2">
          <g id="Group-5" transform="translate(0.000000, 1889.000000)">
            <g id="color/verde" transform="translate(109.000000, 382.000000)">
              <path d="M32,3.58417542 C30.8275916,4.14428313 29.5576908,4.53205001 28.2302945,4.70169802 C29.5876885,3.82652972 30.6226076,2.43972456 31.1200687,0.786329679 C29.850168,1.59687017 28.4452777,2.18659896 26.9403953,2.50165955 C25.7504882,1.13370417 24.0406218,0.272 22.1557691,0.272 C18.5335521,0.272 15.5937817,3.43876284 15.5937817,7.34066705 C15.5937817,7.89808194 15.653777,8.43395422 15.7612687,8.95097673 C10.3016952,8.65476592 5.46957269,5.84614887 2.22732599,1.56455626 C1.65987032,2.60667975 1.33739552,3.82652972 1.33739552,5.1217788 C1.33739552,7.57225005 2.4998047,9.73997461 4.25716741,11.000217 C3.18225139,10.9705959 2.1773299,10.644764 1.28989923,10.1169702 L1.28989923,10.2031406 C1.28989923,13.6311075 3.55472229,16.4908882 6.55198813,17.1452449 C6.0045309,17.3014287 5.4195766,17.3849063 4.82462308,17.3849063 C4.40465589,17.3849063 3.99468792,17.3445139 3.58471994,17.2610364 C4.42465432,20.0696534 6.84446528,22.1081223 9.71674088,22.1727501 C7.46941645,24.0658065 4.63713772,25.1914076 1.56237794,25.1914076 C1.03491915,25.1914076 0.517459573,25.1590937 0,25.0971587 C2.90977267,27.1060065 6.35700336,28.272 10.0667135,28.272 C22.1357706,28.272 28.7377549,17.5006978 28.7377549,8.16197884 L28.7177564,7.24641816 C30.0076557,6.24737988 31.1200687,5.00598731 32,3.58417542 Z" id="Mask"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Twitter
