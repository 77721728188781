import * as React from "react"

function StarPlus(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="44" height="46" viewBox="0 0 44 46">
      <defs>
        <path id="s1wjss7pxa" d="M8.708.546c.602 0 1.089.488 1.089 1.089v6.012h5.983c.603 0 1.092.49 1.092 1.093s-.489 1.092-1.092 1.092H9.796v6.013c0 .601-.486 1.089-1.088 1.089-.6 0-1.088-.488-1.088-1.089V9.832H1.636c-.604 0-1.093-.489-1.093-1.092 0-.603.49-1.093 1.093-1.093h5.982V1.635c0-.601.488-1.089 1.09-1.089z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-390 -1076) translate(390 1076)">
            <g fillRule="nonzero">
              <path fill="#39FF14" d="M42.088 15.544L28.43 14.269 23.005 1.586c-.38-.888-1.63-.888-2.01 0L15.57 14.269 1.912 15.544c-.957.089-1.343 1.286-.622 1.924l10.305 9.114-3.016 13.47c-.21.944.801 1.684 1.627 1.19L22 34.192l11.794 7.05c.826.494 1.838-.246 1.626-1.19l-3.015-13.47L42.71 17.47c.721-.638.335-1.836-.622-1.925z" />
              <path fill="#37D719" d="M22.986 1.586c-.38-.888-1.629-.888-2.008 0l-5.42 12.683L1.91 15.544c-.956.089-1.342 1.286-.621 1.924l10.296 9.114-3.013 13.47c-.211.944.8 1.684 1.625 1.19l2.63-1.573c.364-15.098 7.326-25.73 12.839-31.812l-2.68-6.271z" />
              <path fill="#FFF" d="M43.913 16.35c-.217-.674-.786-1.133-1.484-1.199L29.168 13.91l-2.629-6.162c-.139-.325-.512-.476-.834-.335-.323.14-.472.517-.333.842l2.778 6.512c.092.215.293.363.525.385l13.636 1.277c.27.025.365.23.393.317.028.087.071.308-.133.49L32.282 26.36c-.174.155-.251.394-.2.623l3.011 13.486c.06.268-.104.422-.177.475-.074.054-.269.164-.503.024l-11.775-7.059c-.2-.12-.45-.12-.649 0l-11.775 7.059c-.234.14-.43.03-.503-.024-.073-.053-.237-.207-.177-.475l1.554-6.961c.077-.346-.138-.69-.48-.767-.343-.078-.682.139-.76.484l-1.554 6.962c-.154.691.103 1.379.67 1.795.568.416 1.295.45 1.898.089l11.451-6.864 11.451 6.864c.278.166.582.249.885.249.354 0 .707-.113 1.013-.338.568-.416.824-1.104.67-1.795l-2.928-13.115L43.409 18.2c.528-.467.72-1.176.504-1.85zM9.815 30.484c-.077.352.138.7.48.78.047.01.093.016.14.016.29 0 .551-.206.618-.509l.843-3.847c.05-.233-.026-.476-.2-.634l-10.27-9.279c-.203-.184-.16-.41-.132-.498.027-.088.122-.296.392-.322l13.61-1.298c.231-.022.432-.173.524-.392l5.406-12.913c.108-.256.33-.284.42-.284.09 0 .312.028.419.284l1.468 3.507c.139.331.512.484.834.341.321-.142.47-.526.331-.856L23.23 1.072C22.953.411 22.346 0 21.646 0c-.7 0-1.308.41-1.585 1.072L14.804 13.63 1.569 14.892C.87 14.96.304 15.425.087 16.11c-.216.685-.024 1.406.502 1.881l9.987 9.024-.76 3.47z" />
            </g>
            <rect width="21.75" height="21.84" x="19.833" y="22.66" fill="#39FF14" stroke="#0C2A1A" strokeWidth="3" rx="2" />
            <g transform="translate(22 24.84)">
              <mask id="u2lvn7foxb" fill="#fff">
                <use xlinkHref="#s1wjss7pxa" />
              </mask>
              <use fill="#D8D8D8" xlinkHref="#s1wjss7pxa" />
              <g fill="#005B18" mask="url(#u2lvn7foxb)">
                <g>
                  <path d="M0 0H17.417V17.48H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StarPlus
