import * as React from "react"

function SvgTotalSecurity(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" {...props}>
      <defs>
        <style>{".total-security_svg__prefix__cls-3{fill:#00a750}"}</style>
      </defs>
      <g
        id="total-security_svg__prefix__Seguranc\u0327a_completa"
        data-name="Seguranc\u0327a completa"
        opacity={0.903}
      >
        <path
          id="total-security_svg__prefix__Path_179"
          d="M77.646 69.88a7.555 7.555 0 00-3.435.844 7.526 7.526 0 00-5.664-.508 11.45 11.45 0 011.343-1.981 12.4 12.4 0 10-18.783.146 10.239 10.239 0 012.436 6.8v6.407a.706.706 0 00.207.491l2.75 2.759a.677.677 0 00.482.2h6.887a.7.7 0 00.491-.2l1.558-1.559a7.557 7.557 0 008.3.921 7.573 7.573 0 103.428-14.32zM52.14 67.487a10.932 10.932 0 01-2.634-8.723 11.065 11.065 0 019.658-9.472 11.02 11.02 0 019.676 18.057 12.429 12.429 0 00-2.212 3.772 7.6 7.6 0 00-3.159 4.28H54.92v-.2a11.635 11.635 0 00-2.78-7.714zm11.079 10.66h-8.3V76.77h8.3c-.017.224-.034.456-.034.689s.015.464.034.688zm-8.3 1.378h8.557a7.9 7.9 0 00.542 1.378h-9.1zm8.668 4.133H57.27l-1.377-1.378h9.03c.009.009.017.017.017.026zm7.171 0a6.2 6.2 0 116.2-6.2 6.2 6.2 0 01-6.199 6.201zm6.887 0a6.2 6.2 0 01-2.057-.362 7.58 7.58 0 000-11.677 6.2 6.2 0 112.057 12.039z"
          data-name="Path 179"
          transform="translate(-43.232 -43.036)"
          fill="#fff"
        />
        <path
          id="total-security_svg__prefix__Path_180"
          d="M184 0h1.6v3.2H184z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 180"
          transform="translate(-167.984)"
        />
        <path
          id="total-security_svg__prefix__Path_181"
          d="M21.7 266.7l2.771-1.6.8 1.39-2.771 1.6z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 181"
          transform="translate(-19.529 -242.124)"
        />
        <path
          id="total-security_svg__prefix__Path_182"
          d="M265.1 24.471l1.6-2.771 1.39.8-1.6 2.771z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 182"
          transform="translate(-242.131 -19.529)"
        />
        <path
          id="total-security_svg__prefix__Path_183"
          d="M326.6 90.7l2.771-1.6.8 1.39-2.771 1.6z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 183"
          transform="translate(-298.359 -81.378)"
        />
        <path
          id="total-security_svg__prefix__Path_184"
          d="M352 184h3.2v1.6H352z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 184"
          transform="translate(-321.523 -167.98)"
        />
        <path
          id="total-security_svg__prefix__Path_185"
          d="M326.6 266.39l.8-1.39 2.771 1.6-.8 1.39z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 185"
          transform="translate(-298.359 -242.033)"
        />
        <path
          id="total-security_svg__prefix__Path_186"
          d="M89.1 22.5l1.39-.8 1.6 2.771-1.39.8z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 186"
          transform="translate(-81.38 -19.529)"
        />
        <path
          id="total-security_svg__prefix__Path_187"
          d="M21.7 90.39l.8-1.39 2.771 1.6-.8 1.39z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 187"
          transform="translate(-19.529 -81.287)"
        />
        <path
          id="total-security_svg__prefix__Path_188"
          d="M0 184h3.2v1.6H0z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 188"
          transform="translate(0 -167.98)"
        />
        <path
          id="total-security_svg__prefix__Path_189"
          d="M290.4 346.4a.8.8 0 01.8.8h1.6a2.391 2.391 0 00-1.6-2.251V344h-1.6v.95a2.394 2.394 0 00.8 4.651.8.8 0 11-.8.8H288a2.391 2.391 0 001.6 2.251v.95h1.6v-.95a2.394 2.394 0 00-.8-4.651.8.8 0 010-1.6z"
          className="total-security_svg__prefix__cls-3"
          data-name="Path 189"
          transform="translate(-263.208 -315.604)"
        />
      </g>
    </svg>
  )
}

export default SvgTotalSecurity
