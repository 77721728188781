import * as React from "react"

function CloseGreen(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" {...props}>
      <defs>
        <path
          id="close-green_svg__a"
          d="M1.617.921l19.767 19.767a.545.545 0 010 .765.531.531 0 01-.348.14c-.14 0-.279-.07-.418-.14L.851 1.687a.548.548 0 010-.766.546.546 0 01.766 0zm19.767-.07a.546.546 0 010 .766L1.687 21.453c-.14.07-.279.14-.418.14-.14 0-.278-.07-.418-.14a.547.547 0 010-.765L20.618.85a.548.548 0 01.766 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={21} cy={21} r={21} fill="#39FF14" />
        <g transform="translate(9.882 9.882)">
          <mask id="close-green_svg__b" fill="#fff">
            <use xlinkHref="#close-green_svg__a" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#close-green_svg__a" />
          <g fill="#000" mask="url(#close-green_svg__b)">
            <path d="M0 0h22.235v22.235H0z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloseGreen
