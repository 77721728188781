import _get from "lodash/get";
import _replace from "lodash/replace";
import _size from "lodash/size";
import _slice from "lodash/slice";
import _join from "lodash/join";
import _isEmpty from 'lodash/isEmpty';

export function getStepDataToContact(steps) {
  const contactDateStep = _get(steps, "[await-when]", {});
  const contactDate = _get(contactDateStep, "value", "");
  return {
    companyName:  _get(steps, "[check-user].value.name", ""),
    documentNumber: _get(steps, "[await-cnpj-cpf].value", ""),
    userPhone: _get(steps, "[await-phone].value", ""),
    momentOptions: _get(steps, "[ask-moment-options].value", ""),
    timeframe: contactDate === "" ? "agora" : formatDate(contactDateStep)
  };
}

export function formatDocument(documentNumber) {
  return documentNumber
    .replace(/^(\d{2})(\d)/,"$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    .replace(/\.(\d{3})(\d)/,".$1/$2")
    .replace(/(\d{4})(\d)/,"$1-$2")
}

export function formatDocumentCPF(cpf) {
  return cpf
    .replace(/\D/g,"")
    .replace(/(\d{3})(\d)/,"$1.$2")
    .replace(/(\d{3})(\d)/,"$1.$2")
    .replace(/(\d{3})(\d{1,2})$/,"$1-$2")
}

export function getWhatsLink(agentPhoneFormatted, productSelected) {
  const complementMessage = productSelected ? ` em ${productSelected} do` : ' nas ofertas do'
  return `https://wa.me/55${agentPhoneFormatted}?text=Estou com interesse${complementMessage} corporativo da Algar Telecom`
}

export function formatAgentPhone(agentPhone) {
  return _replace(agentPhone, /[^\d]+/g, "")
}

export function checkTelephoneNotMock(telephone) {
  switch (telephone) {
    case '00000000000':
    case '0000000000':
    case '1111111111':
    case '11111111111':
    case '22222222222':
    case '2222222222':
    case '3333333333':
    case '33333333333':
    case '44444444444':
    case '4444444444':
    case '5555555555':
    case '55555555555':
    case '66666666666':
    case '6666666666':
    case '7777777777':
    case '77777777777':
    case '88888888888':
    case '8888888888':
    case '9999999999':
    case '99999999999': return true;
    default: return false
  }

}

export function payloadToSendWhatsapp(data) {
  const {agentPhoneWhats, agentName, formattedDocNumber, companyName, userPhone, timeframe, productSelected} = data;
  const complementMessage = productSelected ? `sobre ${productSelected} em` : ''
  return {
    to: `${agentPhoneWhats}@c.us`,
    msg: `${agentName}, o cliente de CNPJ ${formattedDocNumber}, razão social ${companyName}, telefone ${userPhone} deseja falar com você ${complementMessage} *${timeframe} no WhatsApp*. Caso ele não entre em contato direto com você, sugerimos contactá-lo o quanto antes. `,
  }
}

export function validarCNPJ(cnpj) {

  if (cnpj === "") return false

  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

  if (resultado != digitos.charAt(0))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

  return (resultado == digitos.charAt(1))
}

export function validarCPF(strCPF) {
  strCPF = strCPF.replace(/[^\d]+/g,'');

  let soma;
  let resto;

  soma = 0;

  if (strCPF === "00000000000") return false;

  for (let i=1; i<=9; i++) soma += parseInt(strCPF.substring(i-1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11))  resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10)) ) return false;

  soma = 0;

  for (let i = 1; i <= 10; i++) soma += parseInt(strCPF.substring(i-1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11))  resto = 0;
  return resto === parseInt(strCPF.substring(10, 11));
}

export function commercialTime() {
   //verifica se o horário permite o usuário falar "AGORA"
   const agora = new Date()
   const diaSemana = agora.getDay()
   const hora = agora.getHours()
   // é dia da SEMANA?
   if (diaSemana > 0 && diaSemana < 7) {
     // ESTA DENTRO DO HORARIO COMERCIAL
     return (hora > 7 && hora < 18)
   }

   return false;
}

export function getManualDate(dateString) {
  if (!dateString) {
    return ''
  }

  const [day, month, yearAndHour] = dateString.split('/')
  const [year, hours] = yearAndHour.split('-')
  const [hour, minute] = hours.split(':')
  return new Date(year, (+month) - 1, day, hour, minute)
}

export function formatDate(stringDate) {
  const stepDateValid = stringDate.value && stringDate.value.includes("T")

  const [year, month, dayAndHour] = stringDate.value && stringDate.value.split(stepDateValid ? "-" : "/")
  const [day, hour] = dayAndHour && dayAndHour.split(stepDateValid ? "T" : "-")

  return stepDateValid
    ? `${day}/${month}/${year} - ${hour}`
    : `${year}/${month}/${day} - ${hour}`
}

export function isDevelopmentMode() {
  return process.env.NODE_ENV === "development"
}

export function onlyNumbers(string) {
  return string ? string.replace(/\D/g, "") : ''
}

export function validCEP(cep) {
  const cepToValid = onlyNumbers(cep+"")

  if(_size(cepToValid) >= 8){
    return cepToValid
  }

  const numbers_zeros = "0000000"

  const newCep = _slice(numbers_zeros + cepToValid, -8)
  
  return _join(newCep, '')
}

export const maskCPF = cpf => {
  if (!_isEmpty(cpf)) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  }
  return '';
};

export const maskCNPJ = cnpj => {
  if (!_isEmpty(cnpj)) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  return '';
};

export const maskCPFCNPJ = number => {
  if (!number) return '';
  number = number.replace(/\D/g, '');
  if (number.length === 14) {
    return maskCNPJ(number);
  }
  return maskCPF(number);
};

export const States = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espirito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];
