export const PayloadExcel  = (form,id_channel) => {

    const {
        email,
        fixedPhone,
        documentNumber,
        name,
        city,
        state,
        razaoSocial
    } = form;

    const corporateLeadData = {
        CpfCnpj:documentNumber,
        IdCanal: id_channel ? id_channel : 31,
        RazaoSocial:razaoSocial,
        NomeEmpresa:"",
        Telefone1:fixedPhone,
        NomePessoa:name,
        Email:email,
        Cidade:city,
        EstadoUf:state,
        Bairro:"",
        Logradouro:"",
        NrLogradouro:"",
        Complemento:"",
        CEP:"",
        NumEmployees:"",
        NumBrands:"",
        NomeCampanha: "FIGITAL",
        NomeSubCampanha: "EMPRESA DIGITAL",
        IdSubCampanha: "861",
        UrlOrigem:"https://empresadigital.algartelecom.com.br/"
      };

    return corporateLeadData;
}