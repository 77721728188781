import * as React from "react"

function SvgDelivery(props) {
  return (
    <svg width={120} height={110} viewBox="0 0 120 110" {...props}>
      <defs>
        <path
          id="delivery_svg__a"
          d="M66.48.629a2.144 2.144 0 012.549-.364l11.456 6.297a2.147 2.147 0 01-2.065 3.763L68.375 4.804l-9.937 9.946 35.743 19.645 9.938-9.946-11.416-6.274a2.146 2.146 0 012.064-3.764l13.92 7.652c.592.325.997.91 1.091 1.579a2.144 2.144 0 01-.606 1.82l-11.58 11.59 11.58 11.591a2.147 2.147 0 01-.484 3.4l-11.985 6.586v22.017c0 .784-.426 1.505-1.112 1.882l-39.659 21.797a2.14 2.14 0 01-2.064 0L14.209 82.528a2.146 2.146 0 01-1.112-1.882V58.629L1.112 52.04a2.142 2.142 0 01-1.09-1.578 2.143 2.143 0 01.606-1.82l11.58-11.59L.628 25.461a2.144 2.144 0 01-.606-1.82 2.145 2.145 0 011.09-1.58L40.772.266a2.141 2.141 0 012.547.364L54.9 12.22zm25.934 60.358L69.03 73.84a2.142 2.142 0 01-2.548-.364L54.9 61.885 43.32 73.476a2.144 2.144 0 01-2.548.364L17.385 60.987v18.39l35.37 19.44V76.873a2.145 2.145 0 114.289 0v21.942l35.37-19.44v-18.39zM54.9 67.623c.552 0 1.094.224 1.485.616.39.393.615.934.615 1.488 0 .554-.225 1.095-.615 1.489-.39.391-.933.615-1.485.615s-1.094-.224-1.485-.615a2.132 2.132 0 01-.615-1.49c0-.553.225-1.094.615-1.487a2.11 2.11 0 011.485-.616zM15.62 39.709l-9.938 9.947 35.743 19.645 9.938-9.947-35.742-19.645zm78.56 0L58.438 59.353l9.937 9.948 35.744-19.646-9.939-9.946zM54.9 17.702l-35.203 19.35L54.9 56.4l35.203-19.348L54.9 17.702zM41.425 4.804L5.682 24.449l9.937 9.946L51.362 14.75l-9.937-9.946zM86.7 10.519c.552 0 1.094.224 1.485.616.39.392.615.934.615 1.488 0 .553-.225 1.095-.615 1.487-.393.392-.933.617-1.485.617s-1.094-.225-1.485-.617a2.112 2.112 0 01-.615-1.487 2.105 2.105 0 012.1-2.104z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse cx={77.25} cy={41.926} fill="#FBBD0C" rx={41.85} ry={41.926} />
        <g transform="translate(5.1 5.11)">
          <mask id="delivery_svg__b" fill="#fff">
            <use xlinkHref="#delivery_svg__a" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#delivery_svg__a" />
          <g fill="#28384A" mask="url(#delivery_svg__b)">
            <path d="M-5.1-5.476h120v110.367h-120z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgDelivery
