import * as React from "react"

function InstagramNew(props) {
    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
            <title>icon/redesocial/instagran</title>
            <defs>
                <path d="M25.1350427,32 L6.86495726,32 C3.09059829,32 0,29.0118846 0,25.344652 L0,6.65534805 C0,2.98811545 3.09059829,0 6.86495726,0 L25.1350427,0 C28.9094017,0 32,2.98811545 32,6.65534805 L32,25.344652 C32,29.0118846 28.9094017,32 25.1350427,32 Z M6.86495726,2.74363328 C4.59487179,2.74363328 2.76239316,4.50933786 2.76239316,6.65534805 L2.76239316,25.344652 C2.76239316,27.5178268 4.59487179,29.2563667 6.86495726,29.2563667 L25.1350427,29.2563667 C27.4051282,29.2563667 29.2376068,27.4906621 29.2376068,25.344652 L29.2376068,6.65534805 C29.2376068,4.50933786 27.4051282,2.74363328 25.1350427,2.74363328 L6.86495726,2.74363328 Z M16.6666667,24 C11.8801743,24 8,20.4183007 8,16 C8,11.5816993 11.8801743,8 16.6666667,8 C21.453159,8 25.3333333,11.5816993 25.3333333,16 C25.3333333,20.4183007 21.453159,24 16.6666667,24 Z M16.6666667,10.6405229 C13.4662309,10.6405229 10.8605664,13.0457516 10.8605664,16 C10.8605664,18.9542484 13.4662309,21.3594771 16.6666667,21.3594771 C19.8671024,21.3594771 22.4727669,18.9542484 22.4727669,16 C22.4727669,13.0457516 19.8671024,10.6405229 16.6666667,10.6405229 Z M26.6666667,7.33333333 C26.6666667,8.44444444 25.7777778,9.33333333 24.6666667,9.33333333 C23.5555556,9.33333333 22.6666667,8.44444444 22.6666667,7.33333333 C22.6666667,6.22222222 23.5555556,5.33333333 24.6666667,5.33333333 C25.7777778,5.33333333 26.6666667,6.22222222 26.6666667,7.33333333 Z" id="path-1"></path>
                <radialGradient cx="0%" cy="100%" fx="0%" fy="100%" r="92.9603583%" gradientTransform="translate(0.000000,1.000000),rotate(-112.151459),scale(1.000000,1.861323),translate(-0.000000,-1.000000)" id="radialGradient-3">
                    <stop stopColor="#FED97D" offset="0%"></stop>
                    <stop stopColor="#FA9852" offset="17.9687418%"></stop>
                    <stop stopColor="#EC4E4F" offset="38.5732395%"></stop>
                    <stop stopColor="#DD2D7A" offset="58.8569667%"></stop>
                    <stop stopColor="#8F3ABE" offset="75.4366257%"></stop>
                    <stop stopColor="#4E60D2" offset="100%"></stop>
                </radialGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="360px---Empresa-digital---Produtos-default" transform="translate(-265.000000, -2269.000000)">
                    <g id="Group-5" transform="translate(0.000000, 1889.000000)">
                        <g id="icon/redesocial/instagran" transform="translate(265.000000, 380.000000)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <use id="Mask" fill="#00A750" fillRule="nonzero" xlinkHref="#path-1"></use>
                            <g id="Group" mask="url(#mask-2)" fill="url(#radialGradient-3)">
                                <g id="color/verde">
                                    <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default InstagramNew
