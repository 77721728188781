import * as React from "react"

function SvgLogoAlgarWhite(props) {
  return (
    <svg
      id="logo-algar-white_svg__prefix__Camada_2"
      width={76.305}
      height={29.984}
      data-name="Camada 2"
      viewBox="0 0 76.305 29.984"
      {...props}
    >
      <title>{props.title}</title>
      <defs>
        <style>
          {
            ".logo-algar-white_svg__prefix__cls-1{fill:none}.logo-algar-white_svg__prefix__cls-2{fill:#fff;fill-rule:evenodd}"
          }
        </style>
      </defs>
      <g id="logo-algar-white_svg__prefix__Camada_1" data-name="Camada 1">
        <path
          id="logo-algar-white_svg__prefix__Path_151"
          d="M0 4.54v19.412h16.419V4.54z"
          className="logo-algar-white_svg__prefix__cls-1"
          data-name="Path 151"
          transform="translate(0 -2.478)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_152"
          d="M12.61 21.474h3.052a.781.781 0 00.768-.786.731.731 0 00-.041-.245L10.63 2.6a.777.777 0 00-.736-.536h-3.32a.786.786 0 00-.745.563L.056 20.439a.077.077 0 000 .023.949.949 0 00-.036.227.777.777 0 00.78.781h2.418a.777.777 0 00.745-.577l1.072-3.584h5.814l1.017 3.584v.023a.786.786 0 00.74.554zM10.23 14.7H5.688l1.794-6.32a16.451 16.451 0 00.509-2.344h.055A14.439 14.439 0 008.5 8.38zm6.586-12.583a.777.777 0 01.454-.695L19.732.145A1.086 1.086 0 0120.241 0a.777.777 0 01.781.772v16.069a1.817 1.817 0 001.817 1.717.772.772 0 01.772.772v1.244a.772.772 0 01-.754.772H22a5.16 5.16 0 01-5.187-5.123z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 152"
          transform="translate(-.011)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_153"
          d="M59.552 26.425v-9.71a3.234 3.234 0 00-1.385-.245c-2.18 0-3.07 1.79-3.07 5.346 0 3.366.863 4.964 2.984 4.964a2.907 2.907 0 001.476-.359m3.673-11.564a.79.79 0 01.454.713v13.344c0 4.219-2.593 6.263-6.4 6.422a10.088 10.088 0 01-4.669-.908.79.79 0 01-.454-1l.413-1.072a.763.763 0 01.731-.545.777.777 0 01.3.059 8.039 8.039 0 002.889.545c2.221 0 3.039-1.076 3.039-2.789v-.858a5.4 5.4 0 01-2.553.454c-3.5 0-6.032-2.725-6.032-7.172.027-4.946 2.525-8.062 7.553-8.062a11.759 11.759 0 014.737.854h.027m14.534 6.917v5.664a.763.763 0 01-.454.69 15.792 15.792 0 01-5.546.863c-4.415 0-6.745-1.59-6.745-4.592 0-3.724 3.084-5.45 8.58-5.173-.132-1.44-.986-2.33-3.02-2.33a8.121 8.121 0 00-2.889.536.7.7 0 01-.3.059.781.781 0 01-.736-.536l-.409-1.049a.772.772 0 01.42-.991 10.3 10.3 0 014.669-.908c3.806.15 6.391 2.148 6.391 6.286zm-4.16 4.946v-5.562c-2.4-.186-4.388.695-4.388 3a2.5 2.5 0 002.78 2.693 6.359 6.359 0 001.608-.132zm6.4-10.787A1.363 1.363 0 0181 14.657 16.642 16.642 0 0186.976 14H87a.768.768 0 01.759.763v1.04a.763.763 0 01-.754.768h-.1a6.463 6.463 0 00-2.78.377v11.285a.759.759 0 01-.758.745H80.8a.763.763 0 01-.763-.763.186.186 0 010-.05z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 153"
          transform="translate(-27.803 -7.631)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_154"
          d="M154 23.749a.777.777 0 01.377-.654.727.727 0 01.777 0l4.387 2.621a.786.786 0 01.359.672.8.8 0 01-.4.69l-4.388 2.612a.722.722 0 01-.736 0 .777.777 0 01-.372-.709v-5.232z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 154"
          transform="translate(-84.054 -12.544)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_155"
          d="M139 15.749a.777.777 0 01.382-.654.736.736 0 01.781 0l4.378 2.621a.8.8 0 01.363.668.777.777 0 01-.409.69l-4.374 2.616a.722.722 0 01-.736 0 .79.79 0 01-.377-.709v-5.232z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 155"
          transform="translate(-75.867 -8.178)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_156"
          d="M139 32.747a.777.777 0 01.382-.654.727.727 0 01.781 0l4.378 2.621a.795.795 0 01-.045 1.363s-4.369 2.607-4.374 2.616a.713.713 0 01-.736 0 .758.758 0 01-.377-.7v-5.246z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 156"
          transform="translate(-75.867 -17.454)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_157"
          d="M105.634 53.936V53H102v.936h1.086V58.9h1.472v-4.964zm4.088 2.543c.064-1.626-.622-2.571-1.976-2.571s-2.112.972-2.112 2.484A2.22 2.22 0 00107.9 58.9a3.634 3.634 0 001.676-.345l-.313-.872a2.584 2.584 0 01-1.217.259c-.681 0-1.031-.345-1.126-1.054zm-1.258-.518l-1.631.25c-.041-.99.3-1.481.858-1.481s.772.37.772 1.231z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 157"
          transform="translate(-55.672 -28.928)"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_158"
          d="M0 29.977h76.3V0H0z"
          className="logo-algar-white_svg__prefix__cls-1"
          data-name="Path 158"
        />
        <path
          id="logo-algar-white_svg__prefix__Path_159"
          d="M121 57.813h1.363V51H121zm5.9-2.425c.059-1.626-.627-2.571-1.98-2.571s-2.107.972-2.107 2.484a2.22 2.22 0 002.271 2.512 3.634 3.634 0 001.671-.345l-.309-.872a2.512 2.512 0 01-1.208.259c-.686 0-1.031-.345-1.126-1.054zm-1.263-.518l-1.626.25c-.041-.99.3-1.481.854-1.481s.777.372.777 1.231zm4.9 2.593l-.3-.836a.849.849 0 01-.586.2c-.559 0-.886-.563-.886-1.54s.286-1.54.908-1.54a.858.858 0 01.577.191l.286-.772a1.817 1.817 0 00-1.185-.345c-1.09 0-2 .854-2 2.484s.845 2.512 2 2.512a1.653 1.653 0 001.181-.35m4.088-2.153c0-1.44-.709-2.494-2.053-2.494s-2.035 1.054-2.035 2.494.7 2.5 2.044 2.5 2.044-1.063 2.044-2.5m-1.331 0c0 .868-.123 1.576-.713 1.576s-.713-.709-.713-1.576.123-1.608.713-1.608.713.749.713 1.608m9.052 2.5V54.37a1.542 1.542 0 00-1.735-1.553 2.339 2.339 0 00-1.594.491 2.076 2.076 0 00-1.535-.491 4.442 4.442 0 00-1.948.363v4.633h1.394V53.8a1.463 1.463 0 01.568-.086c.477 0 .754.254.754.645v3.456h1.394v-3.867a.79.79 0 01.609-.236.618.618 0 01.695.659v3.443z"
          className="logo-algar-white_svg__prefix__cls-2"
          data-name="Path 159"
          transform="translate(-66.043 -27.836)"
        />
      </g>
    </svg>
  )
}

export default SvgLogoAlgarWhite
