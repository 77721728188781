import React,{
    useState,
    useEffect
} from 'react';import Button from 'components/Button';
import classnames from "classnames";
import styles from  './form.module.css';
import { Icon } from "components/Icons";
import {States} from 'src/utils/utils';
import Leads from 'src/services/Leads';
import Select from 'react-select';
import {maskCPFCNPJ} from 'src/utils/utils';
import maskPhone from 'src/utils/telefone-mascara';

const Form = ({openModal,onChangeForm,sendLead,form,setForm,messageError,disable,formErros,setFormErros}) => {

    const customStyles = {

        control: base => ({
          ...base,
          height: 50,
          minHeight: 50,
          borderRadius: 8,
          background: `${disable ? 'bg-white-70': 'bg-none'}`
          
        })
    };

    const[states,setStates] = useState("");
    const[counties,setCounties] = useState([]);
    const[filter,setFilter] = useState(true);
    
    const formDiv = 'relative h3 mb4';

    const formInput = classnames(`absolute top-0 left-0 w-100 h-75 f16 pa24 z-1 ${disable ? 'bg-white-70': 'bg-none'} `,
        styles.form__input,
    );

    const formLabel = classnames(`absolute f16  ${disable ? 'white': 'bg-white' } pa0 mh4`,
        styles.form__label
    );

    const validateInput = classnames('f12 fw5 red', styles.warning);

    const handleState = (e) => {
        setStates(e.value);
        setForm({
            ...form,
            state: e.value
        });

        setFormErros({
            ...formErros,
            state:''
        });
    }

    const handleCountie = (e) => {
        setForm({
            ...form,
            city: e.value
        });

        setFormErros({
            ...formErros,
            city:''
        });
        
    }  

    const fetchCounties = async () => {

        try{
            const result = await  Leads.getStates(states);
            
            setCounties(result.data);
            
            setFilter(false);
        }catch(error){
            setCounties("");
            setFilter(true);
        }
    }

    useEffect(() => {
        if(states !== "")
            fetchCounties();
    },[states]);

    const list_counties =  counties.map((item) => {
        return  { 
           value:item.nome, 
           label: item.nome 
        }
   });

    return(
        <div className="flex justify-center items-center">
            
            <form  className={classnames('pv32 h-auto', styles.form)}>
    
                <button
                    onClick={() => openModal()} 
                    className={classnames('f24 ph12 absolute pointer top-1 right-1',styles.button_close)}
                >
                    <Icon 
                        name="Fechar" 
                        aria-label="Fechar" 
                        title="Fechar"
                        className={styles.iconClose} 
                    />
                </button>
                <h3 className={`f18 f24-m  f24-l tc  ${disable ? 'white' : 'te-papa-green'}  mt0 mh0 mb24 fw7 mt16`}>
                   <b>Preencha o formulário que entraremos em contato</b>
                </h3>
               
                <div className={formDiv}>
                    <input 
                        type="text"  
                        onChange={onChangeForm('name')}  
                        className={formInput} 
                        placeholder=" "
                        id="name"
                        name="name"
                        required
                        autoComplete="off"
                        value={form.name}
                        aria-required="true"
                    />
                    {!disable && <label className={formLabel}>*Nome</label>}
                </div>
                <p className={validateInput}>
                    {formErros.name}
                </p>
            
                <div class={formDiv}>
                    <input 
                        type="email"  
                        onChange={onChangeForm('email')}  
                        className={formInput} 
                        placeholder=" "
                        id="email"
                        name="email"
                        required
                        autoComplete="off"
                        value={form.email}
                        aria-required="true"
                    />
                    {!disable &&  <label className={formLabel}>*E-mail</label> }
                </div>
                <p className={validateInput}>
                    {formErros.email}    
                </p>
                
                <div class={formDiv}>
                    <input 
                        type="tel"  
                        onChange={onChangeForm('documentNumber')}  
                        className={formInput} 
                        value={maskCPFCNPJ(form.documentNumber)}
                        placeholder=" "
                        id="documentNumber"
                        name="documentNumber"
                        required
                        autoComplete="off"
                        aria-required="true"
                    />
                    {!disable &&  <label className={formLabel}>*CNPJ</label> }
                </div>
                <p className={validateInput}>
                    {formErros.documentNumber}    
                </p>


                <div class={formDiv}>
                    <input 
                        type="text"  
                        onChange={onChangeForm('razaoSocial')}  
                        className={formInput} 
                        placeholder=" "
                        id="razaoSocial"
                        name="razaoSocial"
                        required
                        autoComplete="off"
                        value={form.razaoSocial}
                        aria-required="true"
                    />
                    {!disable &&  <label className={formLabel}>*Razão social</label> }
                </div>
                <p className={validateInput}>
                    {formErros.razaoSocial}    
                </p>
            
                <div class={formDiv}>
                    <input 
                        type="tel" 
                        className={formInput} 
                        onChange={onChangeForm('fixedPhone')}
                        value={maskPhone(form.fixedPhone)}  
                        placeholder=" "
                        id="fixedPhone"
                        name="fixedPhone"
                        required
                        autoComplete="off"
                        aria-required="true"
                    />
                    {!disable &&  <label className={formLabel}>*Telefone</label>}
                </div>
                <p className={validateInput}>
                    {formErros.fixedPhone}    
                </p>
                
                <div className={formDiv}>
                    <Select 
                        styles={customStyles}
                        onChange={(e)=> handleState(e)} 
                        options={States} 
                        classNamePrefix="select"
                        textFieldProps={{
                            label: "Label",
                            InputLabelProps: {
                                shrink: true
                            }
                        }}
                        isSearchable={true}
                        name="uf"
                        id="uf"
                        placeholder="*Selecione o Estado"
                        required
                        aria-required="true"
                        noOptionsMessage={() => 'Sem opções!'}
                    />
                </div>
                <p className={validateInput}>
                    {formErros.state}    
                </p>
                <div className={formDiv}>
                    <Select 
                        styles={customStyles}
                        onChange={(e) => handleCountie(e)}
                        isDisabled={filter}
                        options={list_counties}
                        classNamePrefix="select"
                        textFieldProps={{
                            label: "Label",
                            InputLabelProps: {
                                shrink: true
                            }
                        }}
                        noOptionsMessage={() => 'Sem opções!'}
                        isSearchable={true}
                        name="cidade"
                        id="cidade"
                        placeholder="*Selecione a Cidade"
                        required
                        aria-required="true"
                    />
                </div>
                <p className={validateInput}>
                    {formErros.city}    
                </p>

                {messageError && form.documentNumber &&
                    <h4 className="f20 tc red mt0 mh0 mb24  mt16">
                        <b> {messageError}</b>
                    </h4>
                }

                <div className="tc white">
                   <Button
                        text={'Tenho interesse'}
                        type={`${disable ? '' : 'solid'}`}
                        color="supernova"
                        size="medium"
                        className="mb12"
                        onClick={(e) => sendLead(e)}
                        disabled={disable ? true : false}
                    />
                </div>
              </form>

        </div>
    )
}

export default Form;