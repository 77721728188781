import StringUltils from 'src/utils/StringUtils';

export const Validate = (values) =>{

    const {
        email, 
        fixedPhone,
        name,
        documentNumber,
        city,
        state,
        razaoSocial
    } = values;

    const errors = {};

    if(!name) 
        errors.name = "Informe um nome!";
  
    if(!StringUltils.validateEmail(email)) 
        errors.email = "Digite um e-mail válido!";

    if(!StringUltils.validateCPFCNPJ(documentNumber)) 
        errors.documentNumber = "Digite um CNPJ válido!";
  
    if(fixedPhone.length < 11) 
        errors.fixedPhone = "Digite um número de telefone válido!";
  
    if(!state) 
        errors.state = "Informe o estado!";
  
    if(!city) 
        errors.city = "Informe a cidade!";

    if(!razaoSocial) 
        errors.razaoSocial = "Informe a razão social!";
  
    return errors;
}