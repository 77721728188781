import * as React from "react"

function Chat(props) {
  return (
    <svg width={40} height={31.662} viewBox="0 0 40 31.662" {...props}>
      <g transform="translate(-1087 -695)">
        <path
          d="M1116.982 714.654v-13.156a3.675 3.675 0 00-3.671-3.67h-22.64a3.675 3.675 0 00-3.671 3.67v13.156a3.675 3.675 0 003.671 3.674h3.3l.07 3.315a1.225 1.225 0 001.959.948l5.656-4.263h11.655a3.676 3.676 0 003.671-3.674zm-15.736 1.224a1.224 1.224 0 00-.737.247l-4.076 3.072-.045-2.122a1.224 1.224 0 00-1.223-1.2h-4.494a1.225 1.225 0 01-1.224-1.224v-13.153a1.225 1.225 0 011.224-1.224h22.64a1.225 1.225 0 011.224 1.224v13.156a1.225 1.225 0 01-1.224 1.224h-12.065zm23.691-6.731v10.1a3.675 3.675 0 01-3.671 3.671h-3.3l-.066 2.551a1.224 1.224 0 01-1.223 1.193c-.488 0-.216.085-6.337-3.748h-5.595a1.224 1.224 0 010-2.448c6.491 0 6.188-.069 6.6.186l4.168 2.609.041-1.6a1.224 1.224 0 011.223-1.193h4.494a1.225 1.225 0 001.224-1.224v-10.1a1.225 1.225 0 00-1.224-1.224 1.224 1.224 0 110-2.448 3.676 3.676 0 013.666 3.675zm-15.297-3.289a1.224 1.224 0 01-1.224 1.224h-12.85a1.224 1.224 0 010-2.448h12.85a1.224 1.224 0 011.224 1.224zm-2.218 5.354a1.224 1.224 0 01-1.222 1.224h-8.416a1.224 1.224 0 110-2.448h8.416a1.224 1.224 0 011.222 1.224z"
          fill="#fff"
        />
        <circle
          cx={4}
          cy={4}
          r={4}
          data-name="Ellipse 5"
          transform="translate(1119 695)"
          fill="#00f804"
        />
      </g>
    </svg>
  )
}

export default Chat
