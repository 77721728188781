export const WarningLabel = (field,values,formErros, setFormErros) => {

    if(field === 'name' && values)  setFormErros({
        ...formErros,
        name:''
    });
      
    if(field === 'razaoSocial' && values) setFormErros({
        ...formErros,
        razaoSocial:''
    });
  
    if(field === 'email' && values) setFormErros({
        ...formErros,
        email:''
    });
      
  
    if(field === 'documentNumber' && values) setFormErros({
        ...formErros,
        documentNumber:''
    });
      
  
    if(field === 'fixedPhone' && values)setFormErros({
        ...formErros,
        fixedPhone:''
    });
}