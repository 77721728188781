import * as React from "react"

function ArrowRight(props) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>{props.title}</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
        <g id="360px---Empresa-digital---Produtos-default" transform="translate(-14.000000, -2087.000000)">
          <g id="Group-5" transform="translate(0.000000, 1889.000000)">
            <g id="Group-3" transform="translate(14.000000, 130.000000)">
              <g id="Group-15" transform="translate(0.000000, 68.000000)">
                <g id="Group-5-Copy-2" transform="translate(10.000000, 10.000000) rotate(90.000000) translate(-10.000000, -10.000000) ">
                  <g id="down-arrow" transform="translate(1.000000, 5.000000)" fill="#1e333b" fillRule="nonzero">
                    <path d="M18.7239316,0.253968254 C18.4641026,1.62370117e-15 18.0418803,1.62370117e-15 17.7820513,0.253968254 L9.5,8.36507937 L1.2017094,0.253968254 C0.941880342,1.62370117e-15 0.51965812,1.62370117e-15 0.25982906,0.253968254 C-4.57966998e-16,0.507936508 -4.57966998e-16,0.920634921 0.25982906,1.17460317 L9.01282051,9.73015873 C9.14273504,9.85714286 9.30512821,9.92063492 9.48376068,9.92063492 C9.64615385,9.92063492 9.82478632,9.85714286 9.95470085,9.73015873 L18.7076923,1.17460317 C18.9837607,0.920634921 18.9837607,0.507936508 18.7239316,0.253968254 Z" id="Shape"></path>
                  </g>
                  <rect id="Rectangle-14" x="0" y="0" width="20" height="20"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowRight
