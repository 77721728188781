import React from 'react';

const WhatsappWhite = props => (
  <svg width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>whatsapp</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1366px---Empresa-digital---Produtos-na-nuvem" transform="translate(-1098.000000, -24.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="whatsapp" transform="translate(1098.000000, 24.000000)">
          <path d="M19,0 C8.52296154,0 0,8.52296154 0,19 C0,22.5741923 0.993115385,26.0438846 2.87703846,29.0568462 C2.12653846,31.6861538 0.770961538,36.6597692 0.756346154,36.7116538 C0.686192308,36.9688846 0.761461538,37.2436538 0.953653846,37.4285385 C1.14584615,37.6134231 1.42353846,37.6791923 1.67638462,37.6017308 L9.20769231,35.2851923 C12.1578077,37.0624231 15.5361538,38 19,38 C29.4770385,38 38,29.4770385 38,19 C38,8.52296154 29.4770385,0 19,0 Z M19,36.7333333 C15.6609611,36.7333333 12.4083722,35.8001167 9.59320556,34.03565 C9.47424444,33.9610222 9.33755,33.9233389 9.20085556,33.9233389 C9.12770556,33.9233389 9.05455556,33.9344222 8.98362222,33.95585 L2.33436111,36.0025722 C2.82276667,34.2196333 3.70056667,31.0305889 4.20966111,29.2565167 C4.26877222,29.0511056 4.23552222,28.8294389 4.11951667,28.6498889 C2.25308333,25.7778278 1.26666667,22.4410056 1.26666667,19 C1.26666667,9.22228333 9.22228333,1.26666667 19,1.26666667 C28.7777167,1.26666667 36.7333333,9.22228333 36.7333333,19 C36.7333333,28.7777167 28.7777167,36.7333333 19,36.7333333 Z" id="Shape"></path>
          <path d="M30.6237255,23.0842541 C29.2727202,22.3466225 28.1222434,21.606832 27.2828052,21.0671016 C26.6416998,20.6554672 26.1784352,20.3582556 25.8388543,20.1905793 C24.8896377,19.7249719 24.1702238,20.0538476 23.8965098,20.3258718 C23.8621126,20.3596949 23.8313747,20.3963965 23.8050279,20.4352571 C22.8192185,21.8896507 21.5318847,23.280716 21.1549791,23.3555586 C20.7195251,23.288632 18.6813076,22.149441 16.6555316,20.4906695 C14.587308,18.7959159 13.2860689,17.1731264 13.0950546,16.0677585 C14.4219086,14.7249092 14.8998103,13.8800512 14.8998103,12.9186113 C14.8998103,11.9276663 12.5490904,7.79045262 12.1238824,7.37234145 C11.6972106,6.95351063 10.7362844,6.88802334 9.26745041,7.17587957 C9.1262023,7.20394555 8.99593202,7.27231141 8.89347226,7.37234145 C8.71563137,7.54721411 4.55430105,11.7189705 6.53177459,16.7751652 C8.70245797,22.3243136 14.2740738,28.7744521 21.3774632,29.8222488 C22.1846998,29.9409895 22.9414384,30 23.6498745,30 C27.8287694,30 30.2951224,27.9324726 30.9896532,23.831241 C31.041615,23.5311509 30.894512,23.2317804 30.6237255,23.0842541 Z M21.7045354,28.5530362 C14.0142161,27.4279424 9.17030395,19.9938431 7.6822901,16.2201367 C6.20626428,12.4771346 8.92829565,9.13986809 9.61161319,8.38541923 C10.1675579,8.29330629 10.7527235,8.25456037 10.9969796,8.29184418 C11.50722,8.98415335 13.2739554,12.2410038 13.3518776,12.82731 C13.3518776,13.2111139 13.2237556,13.7455152 11.6982791,15.2346744 C11.5574198,15.3713817 11.4787484,15.5570697 11.4787484,15.7515304 C11.4787484,19.5793349 19.753484,24.8897191 21.2190203,24.8897191 C22.4934974,24.8897191 24.1553377,22.7996326 25.1016425,21.4376769 C25.1563379,21.4398701 25.2484959,21.4581465 25.3796149,21.5224793 C25.6493455,21.6548003 26.1168786,21.9523397 26.7080382,22.3295642 C27.4887584,22.8274127 28.5257227,23.4882865 29.75,24.1732851 C29.1940552,26.7831518 27.6401073,29.4229918 21.7045354,28.5530362 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default WhatsappWhite;
