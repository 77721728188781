import * as React from "react"

function SvgCompleteAdvisory(props) {
  return (
    <svg width={48} height={41} viewBox="0 0 48 41" {...props}>
      <defs>
        <style>{".complete-advisory_svg__prefix__cls-2{fill:#00a750}"}</style>
      </defs>
      <g
        id="complete-advisory_svg__prefix__Acessoria_C"
        data-name="Acessoria C"
        transform="translate(0 -37)"
      >
        <path
          id="complete-advisory_svg__prefix__Path_190"
          d="M40.8 45.038H29.6v-.8A7.226 7.226 0 0022.4 37H7.2A7.226 7.226 0 000 44.234v9.646a7.226 7.226 0 007.2 7.234H8v6.431a.8.8 0 001.36.573l6.97-7h2.07v.8a7.226 7.226 0 007.2 7.234h4.47l8.57 8.611a.78.78 0 00.87.171.81.81 0 00.49-.744v-8.037h.8a7.226 7.226 0 007.2-7.234v-9.646a7.226 7.226 0 00-7.2-7.235zM16 59.507a.808.808 0 00-.57.231L9.6 65.606v-5.3a.8.8 0 00-.8-.8H7.2a5.617 5.617 0 01-5.6-5.626v-9.646a5.617 5.617 0 015.6-5.627h15.2a5.617 5.617 0 015.6 5.627v.8h-2.4a7.226 7.226 0 00-7.2 7.234v7.234zm30.4 2.411a5.617 5.617 0 01-5.6 5.627h-1.6a.8.8 0 00-.8.8v6.9l-7.43-7.466a.791.791 0 00-.57-.231h-4.8a5.617 5.617 0 01-5.6-5.619v-9.646a5.617 5.617 0 015.6-5.627h15.2a5.617 5.617 0 015.6 5.627z"
          data-name="Path 190"
          fill="#fff"
        />
        <path
          id="complete-advisory_svg__prefix__Path_199"
          d="M52.253 197h-19.6a.817.817 0 000 1.634h19.6a.817.817 0 000-1.634z"
          className="complete-advisory_svg__prefix__cls-2"
          data-name="Path 199"
          transform="translate(-9.387 -137)"
        />
        <path
          id="complete-advisory_svg__prefix__Path_200"
          d="M52.253 197H40.817a.817.817 0 000 1.634h11.436a.817.817 0 000-1.634z"
          className="complete-advisory_svg__prefix__cls-2"
          data-name="Path 200"
          transform="translate(-9.387 -141)"
        />
        <path
          id="complete-advisory_svg__prefix__Path_201"
          d="M52.253 197H40.817a.817.817 0 000 1.634h11.436a.817.817 0 000-1.634z"
          className="complete-advisory_svg__prefix__cls-2"
          data-name="Path 201"
          transform="translate(-36.387 -146)"
        />
      </g>
    </svg>
  )
}

export default SvgCompleteAdvisory
