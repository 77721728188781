import * as React from "react"

function SvgFacebookIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M23.9 40zm.2 0zm-.2 0zm.2 0zm-.3 0zm.4 0zm0 0zm-.4 0zm.5 0zm-.6 0zm.6 0zm-.6 0zm-.1 0zm.8 0zm-.8 0zm.8 0zm.1 0zm-1 0zm0 0zm1 0zm.1 0zm-1.2 0zm0 0zm1.2 0zm-1.3 0zm1.4 0zm-1.4 0zm1.4 0zm-1.5 0zm1.6 0zm-1.6 0zm1.6 0zm.1 0zm-1.8 0zm1.8 0zm-1.8 0zm-.1 0zm2 0zm-2 0zm2 0zm.1 0zm-2.2 0zm0 0zm2.2 0zm-2.3 0zm2.4 0zm-2.4 0zm2.4 0zm.1-.1zm-2.6 0zm0 0zm2.6 0zm-2.7 0zm2.8 0zm0 0zm-2.8 0zm-.1 0zm3 0zm-4-.1C13.9 38.6 8 32 8 24c0-8.8 7.2-16 16-16s16 7.2 16 16c0 8-5.9 14.6-13.5 15.8V28.6h3.7l.7-4.6h-4.4v-3c0-1.3.6-2.5 2.6-2.5h2v-3.9s-1.8-.3-3.6-.3c-3.7 0-6 2.2-6 6.2V24h-4.1v4.6h4.1v11.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgFacebookIcon
