import * as React from "react"

function SvgFlexibleConsumption(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
      <defs>
        <style>
          {".flexible-consumption_svg__prefix__cls-3{fill:#00a750}"}
        </style>
      </defs>
      <g
        id="flexible-consumption_svg__prefix__Consumo_flexivel"
        data-name="Consumo flexivel"
        transform="translate(-7.984 -7.927)"
        opacity={0.896}
      >
        <path
          id="flexible-consumption_svg__prefix__Path_216"
          d="M45.736 16.469a13.55 13.55 0 00-26.041 3.387A9.3 9.3 0 0010.511 35.2a1.73 1.73 0 00.126.135 9.219 9.219 0 006.638 2.787h8.516v9.289h-3.328a2.32 2.32 0 100 1.548h4.1a.776.776 0 00.774-.774v-10.06h3.871v13.3a2.32 2.32 0 101.548 0v-13.3h3.874v15.482a.776.776 0 00.774.774h4.1a2.32 2.32 0 100-1.548h-3.326V38.125h6.968a10.836 10.836 0 00.59-21.656zM20.275 48.962a.774.774 0 11.774-.774.776.776 0 01-.774.774zm23.419 3.871a.774.774 0 11-.774.774.776.776 0 01.774-.774zm-11.709 1.548a.774.774 0 11.774-.774.776.776 0 01-.774.774zm19.6-20.388a9.275 9.275 0 01-6.435 2.584H17.276a7.66 7.66 0 01-5.535-2.332l-.1-.1a7.74 7.74 0 018.448-12.521.766.766 0 001.011-.424 1.06 1.06 0 00.058-.252 12 12 0 0123.3-3.474.762.762 0 00.726.513 9.3 9.3 0 016.4 16z"
          data-name="Path 216"
          fill="#fff"
        />
        <path
          id="flexible-consumption_svg__prefix__Path_217"
          d="M105.584 272h-8.519a1.065 1.065 0 000 2.13h8.519a1.065 1.065 0 000-2.13z"
          className="flexible-consumption_svg__prefix__cls-3"
          data-name="Path 217"
          transform="translate(-76.518 -240.423)"
        />
        <path
          id="flexible-consumption_svg__prefix__Path_218"
          d="M186.079 272h-1.04a1.04 1.04 0 100 2.079h1.04a1.04 1.04 0 100-2.079z"
          className="flexible-consumption_svg__prefix__cls-3"
          data-name="Path 218"
          transform="translate(-169.099 -240.424)"
        />
      </g>
    </svg>
  )
}

export default SvgFlexibleConsumption
