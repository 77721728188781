import * as React from "react"

function SvgYoutubeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M24 8c8.8 0 16 7.2 16 16s-7.2 16-16 16S8 32.8 8 24 15.2 8 24 8zm9.6 11.2c-.2-.9-.9-1.5-1.8-1.8C30.3 17 24 17 24 17s-6.3 0-7.8.4c-.9.2-1.5.9-1.8 1.8C14 20.7 14 24 14 24s0 3.3.4 4.8c.2.9.9 1.5 1.8 1.8 1.5.4 7.8.4 7.8.4s6.3 0 7.8-.4c.9-.2 1.5-.9 1.8-1.8.4-1.5.4-4.8.4-4.8s0-3.3-.4-4.8zM22 27v-6l5.2 3-5.2 3z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgYoutubeIcon
