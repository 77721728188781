import _replace from "lodash/replace"

function mask(phone) {
    if(!phone) {
        return '';
    }
    phone = _replace(phone, /[^\d]+/g, "").slice(0, 15)
    if (phone.charAt(0) === "0"){
        phone = phone.slice(1);
    }
    let telefoneFormatado = "(" + phone.slice(0,2)
    if (phone.length > 2) {
        telefoneFormatado = telefoneFormatado + ") " + phone.slice(2,7)
        if (phone.length > 7) {
            telefoneFormatado = telefoneFormatado + "-" + phone.slice(7,11)
        }
    }
    return telefoneFormatado;
}

export default mask
