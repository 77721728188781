import React,{
  useState, 
  useEffect
} from 'react';
import lead from 'src/services/Leads';
import Modal from 'components/Modal';
import Form from './Form';
import Succes from 'components/SuccessModal';
import _get from "lodash/get";
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import styles from  './form.module.css';
import {Validate} from './Validate';
import {WarningLabel} from './WarningLabel';
import {PayloadCrm} from './Payloads/PayloadCrm';
import {PayloadExcel} from './Payloads/PayloadExcel';
import StringUltils from 'src/utils/StringUtils';

const FormLead = ({handleCloseModal, open = true,id_channel, product}) => {

  const[form, setForm] = useState({
    documentNumber:'',
    name:'',
    email:'',
    fixedPhone:'',
    state:'',
    city:'',
    razaoSocial:''

  });

  const [modalIsOpen, setIsOpen] = useState(open);
  const[disable, setDisabled] = useState(false);
  const[visible,setVisible] = useState(false);
  const[messageError, setMessaError] = useState('');
  const[formErros, setFormErros] = useState({});

  const onChangeForm = field => evt =>{

      let value = evt.target.value;

      setForm({
        ...form,
        [field]:value
      });

      WarningLabel(field,value,formErros, setFormErros)
  };

  
  function setBoolean(statusOne, statusTwo){
    setDisabled(statusOne);
    setVisible(statusTwo);
  }

  async function generateLead(typeSegment){
          
    if(typeSegment?.data?.segmento === 'EMPRESARIAL'){
      
      await lead.postLeadMPE(PayloadExcel(form,id_channel));
      await lead.createLeadAlgarCRM(PayloadCrm(form,product,id_channel));
      
      setBoolean(false,true);

    }else{

      const result = await lead.createLeadAlgarCRM(PayloadCrm(form,product,id_channel));

      if(result.status === 201)
        setBoolean(false,true);
    }
}

async function sendLead(e){

  e.preventDefault()
  
  try{

     setFormErros(Validate(form));

     if(form.name &&  StringUltils.validateCPFCNPJ(form.documentNumber) &&  form.fixedPhone.length >= 11 && StringUltils.validateEmail(form.email)  && form.state && form.city && form.razaoSocial){
      setDisabled(true);

      const typeSegment = await lead.leadsWeCallYouRetailQuerySegmentClient(form.documentNumber);
  
      if(typeSegment?.status === 204) {
        setMessaError('CNPJ sem segmentação!');
        setDisabled(false);
        return;
      } 
  
      typeSegment?.status === 200 ? await generateLead(typeSegment) : setDisabled(false);
      
     }

    }catch(error){

   const message = _get(error, 'response.data.message');
  
    if(message){
      setBoolean(false,false);
    }

    if(_get(error, 'response.status') === 400){
      setMessaError("Informe um CNPJ válido!");
      setBoolean(false,false);
    }
  }
}

  const openModal = () => !modalIsOpen ? setIsOpen(true) : setIsOpen(false);

  if(!modalIsOpen) handleCloseModal();

  useEffect(() => {
    setMessaError('');
  },[form]);
    
  return (
    <div>
      <Modal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} disable={disable} >

        {visible && <Succes openModal={openModal}/>}

        {disable && 
          <div className={classNames('flex items-center justify-center black fixed w-90 h-75 z-9999', styles.load)}>
            <ReactLoading  type="spin" color="#0b994f" height={30} width={30} />
          </div>
        }
         
        {!visible && 
          <Form  
            onChangeForm={onChangeForm} 
            openModal={openModal} 
            sendLead={sendLead} 
            disable={disable} 
            form={form} 
            setForm={setForm} 
            messageError={messageError} 
            formErros={formErros}  
            setFormErros={ setFormErros} 
          />
        }
      </Modal>
    </div>
  )
}

export default FormLead;