import * as React from "react"

function SvgLogo(props) {
  return (
    <svg
      id="logo_svg__prefix__Algar_LogoHeader"
      width={168}
      height={66}
      viewBox="0 0 168 66"
      {...props}
    >
      <defs>
        <style>
          {
            ".logo_svg__prefix__cls-1{fill:none}.logo_svg__prefix__cls-2{fill:#fff;fill-rule:evenodd}"
          }
        </style>
      </defs>
      <path
        id="logo_svg__prefix__Path_151"
        d="M0 4.54v42.766h36.172V4.54z"
        className="logo_svg__prefix__cls-1"
        data-name="Path 151"
        transform="translate(0 .003)"
      />
      <path
        id="logo_svg__prefix__Path_152"
        d="M27.757 47.309h6.724a1.721 1.721 0 001.691-1.731 1.611 1.611 0 00-.09-.54L23.394 5.724a1.711 1.711 0 00-1.621-1.181h-7.314a1.731 1.731 0 00-1.641 1.241L.1 45.028a.17.17 0 000 .05 2.091 2.091 0 00-.08.5A1.711 1.711 0 001.731 47.3h5.333a1.711 1.711 0 001.641-1.271l2.361-7.895h12.809l2.241 7.895v.05a1.731 1.731 0 001.631 1.221zM22.514 32.39H12.508l3.952-13.929a36.242 36.242 0 001.121-5.161h.12a31.81 31.81 0 001 5.163zM37.023 4.663a1.711 1.711 0 011-1.531L43.447.32A2.391 2.391 0 0144.568 0a1.711 1.711 0 011.721 1.7v35.4a4 4 0 004 3.782 1.7 1.7 0 011.7 1.7v2.742a1.7 1.7 0 01-1.661 1.7H48.45a11.367 11.367 0 01-11.427-11.282z"
        className="logo_svg__prefix__cls-2"
        data-name="Path 152"
      />
      <path
        id="logo_svg__prefix__Path_153"
        d="M69.912 41.4V20a7.124 7.124 0 00-3.052-.54c-4.8 0-6.764 3.942-6.764 11.777 0 7.415 1.9 10.937 6.574 10.937a6.4 6.4 0 003.252-.79m8.1-25.476a1.741 1.741 0 011 1.571v29.4c0 9.3-5.714 13.8-14.109 14.149a22.224 22.224 0 01-10.286-2 1.741 1.741 0 01-1-2.2l.911-2.361a1.681 1.681 0 011.611-1.2 1.711 1.711 0 01.65.13 17.711 17.711 0 006.364 1.2c4.893 0 6.694-2.371 6.694-6.144V46.56a11.9 11.9 0 01-5.623 1c-7.715 0-13.288-6-13.288-15.8C51 20.864 56.5 14 67.58 14a25.906 25.906 0 0110.436 1.881h.06m32.024 15.24V43.6a1.681 1.681 0 01-1 1.521 34.791 34.791 0 01-12.218 1.9c-9.726 0-14.859-3.5-14.859-10.116 0-8.205 6.794-12.007 18.9-11.4-.29-3.172-2.171-5.133-6.654-5.133a17.891 17.891 0 00-6.369 1.183 1.541 1.541 0 01-.65.13 1.721 1.721 0 01-1.619-1.185l-.9-2.311a1.7 1.7 0 01.92-2.189 22.684 22.684 0 0110.286-2c8.385.33 14.079 4.733 14.079 13.849zm-9.166 10.9V29.76c-5.293-.41-9.666 1.531-9.666 6.614 0 3.722 2.241 5.934 6.124 5.934a14.008 14.008 0 003.542-.29zm14.106-23.768a3 3 0 012.121-2.782c3.072-1.081 7.114-1.611 13.168-1.441h.05a1.691 1.691 0 011.671 1.681V18a1.681 1.681 0 01-1.661 1.691h-.21a14.239 14.239 0 00-6.124.831v24.857a1.671 1.671 0 01-1.671 1.641h-5.664a1.681 1.681 0 01-1.681-1.681.412.412 0 010-.11z"
        className="logo_svg__prefix__cls-2"
        data-name="Path 153"
        transform="translate(-.029 -.048)"
      />
      <path
        id="logo_svg__prefix__Path_154"
        d="M154 24.671a1.711 1.711 0 01.831-1.441 1.6 1.6 0 011.711 0l9.666 5.77a1.731 1.731 0 01.792 1.485 1.751 1.751 0 01-.881 1.521l-9.666 5.754a1.591 1.591 0 01-1.621 0 1.711 1.711 0 01-.821-1.561V24.671z"
        data-name="Path 154"
        transform="translate(-.009 -.011)"
        fill="#f9c747"
        fillRule="evenodd"
      />
      <path
        id="logo_svg__prefix__Path_155"
        d="M139 16.671a1.711 1.711 0 01.841-1.441 1.621 1.621 0 011.721 0l9.646 5.77a1.761 1.761 0 01.8 1.471 1.711 1.711 0 01-.9 1.521l-9.636 5.764a1.591 1.591 0 01-1.621 0 1.741 1.741 0 01-.83-1.561V16.671z"
        data-name="Path 155"
        transform="translate(-.018 -.007)"
        fill="#3ea4da"
        fillRule="evenodd"
      />
      <path
        id="logo_svg__prefix__Path_156"
        d="M139 33.671a1.711 1.711 0 01.841-1.441 1.6 1.6 0 011.721 0l9.646 5.77a1.751 1.751 0 01-.1 3s-9.626 5.744-9.636 5.764a1.571 1.571 0 01-1.621 0 1.671 1.671 0 01-.831-1.551V33.671z"
        data-name="Path 156"
        transform="translate(-.018 -.016)"
        fill="#45994a"
        fillRule="evenodd"
      />
      <path
        id="logo_svg__prefix__Path_157"
        d="M110 55.061V53h-8v2.061h2.391v10.947h3.242V55.061zm9.006 5.6c.14-3.582-1.371-5.663-4.353-5.663S110 57.143 110 60.475s1.811 5.533 5 5.533a8 8 0 003.692-.76l-.69-1.921a5.694 5.694 0 01-2.682.57c-1.5 0-2.271-.76-2.482-2.321zm-2.772-1.141l-3.592.55c-.09-2.181.67-3.262 1.891-3.262s1.706.825 1.706 2.716z"
        className="logo_svg__prefix__cls-2"
        data-name="Path 157"
        transform="translate(-.007 -.024)"
      />
      <path
        id="logo_svg__prefix__Path_158"
        d="M0 65.984h168V0H0z"
        className="logo_svg__prefix__cls-1"
        data-name="Path 158"
      />
      <path
        id="logo_svg__prefix__Path_159"
        d="M121 66.009h3V51h-3zm13.008-5.343c.13-3.582-1.381-5.664-4.363-5.664S125 57.144 125 60.476s1.811 5.533 5 5.533a8.005 8.005 0 003.682-.76l-.68-1.921a5.533 5.533 0 01-2.662.57c-1.511 0-2.271-.76-2.482-2.321zm-2.782-1.141l-3.582.55c-.09-2.181.67-3.262 1.881-3.262s1.701.821 1.701 2.712zm10.787 5.714l-.65-1.841a1.871 1.871 0 01-1.291.43c-1.231 0-1.951-1.241-1.951-3.392s.63-3.392 2-3.392a1.891 1.891 0 011.271.42l.63-1.7a4 4 0 00-2.612-.76c-2.4 0-4.4 1.881-4.4 5.473s1.861 5.533 4.4 5.533a3.642 3.642 0 002.6-.77m9.006-4.743c0-3.172-1.561-5.493-4.523-5.493s-4.483 2.321-4.483 5.493 1.551 5.513 4.5 5.513 4.5-2.341 4.5-5.513m-2.932 0c0 1.911-.27 3.472-1.571 3.472s-1.571-1.561-1.571-3.472.27-3.542 1.571-3.542 1.571 1.651 1.571 3.542m19.942 5.513v-7.585c0-1.9-1.3-3.422-3.822-3.422a5.153 5.153 0 00-3.512 1.081A4.573 4.573 0 00157.312 55a9.786 9.786 0 00-4.293.8v10.209h3.072v-8.845a3.222 3.222 0 011.251-.19c1.051 0 1.661.56 1.661 1.421v7.615h3.072v-8.516a1.741 1.741 0 011.341-.52 1.361 1.361 0 011.531 1.451v7.585z"
        className="logo_svg__prefix__cls-2"
        data-name="Path 159"
        transform="translate(-.029 -.025)"
      />
    </svg>
  )
}

export default SvgLogo
