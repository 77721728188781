import * as React from "react"

function SvgFecharIcon(props) {
  return (
    <svg width={75} height={74} viewBox="0 0 75 74" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#1E333B" cx={37.081} cy={37} r={37} />
        <path
          stroke="#FFF"
          strokeWidth={3}
          strokeLinecap="square"
          d="M23.439 22.858l28.284 28.284M51.723 22.858L23.44 51.142"
        />
      </g>
    </svg>
  )
}

export default SvgFecharIcon
