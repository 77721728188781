import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"

import styles from "./button.module.css"

const Button = ({
  size = "medium",
  type = "solid",
  text,
  onClick,
  className,
  children,
  color = "harlequin",
  ...props
}) => {
  const buttonClasses = classnames(
    "br12 pointer",
    className,
    Button.size[size],
    Button.type[color][type],
    Button.textSize[size],
  )

  return (
    <button 
    
      className={buttonClasses} 
      onClick={onClick} 
      {...props}
    >
      {text || children}
    </button>
  )
}

export default Button

Button.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

Button.size = {
  small: "pv7 ph16",
  medium: "pv10 ph24",
  large: "pv14 ph24",
  xl: classnames(styles.xlSize, "pv14"),
}

Button.textSize = {
  small: "f14 fw6",
  medium: "f16 fw6",
  large: "f16 fw6",
  xl: "f16 fw6",
}

const defaultOutlineClasses = 'ba te-papa-green hover-te-papa-green';
const kelleyGreenOutlineClasses = 'bw1 bw1 bg-transparent b--kelley-green hover-bg-kelley-green'
const harlequinOutlineClasses = 'b--harlequin hover-bg-harlequin'
const supernovaOutlineClasses = 'b--supernova hover-bg-supernova'

const defaultSolidClasses = 'bn te-papa-green';
const kellyGreenSolidClasses = 'bg-kelley-green';
const harlequinSolidClasses = 'bg-harlequin';
const supernovaSolidClasses = 'bg-supernova';
const springgreenSolidClasses = 'bg-spring-green';

Button.type = {
  'kelley-green': {
    outline: classnames(
      defaultOutlineClasses,
      kelleyGreenOutlineClasses,
      styles.buttonHoverKellyGreen,
      styles.outlineKelleyGreen
    ),
    solid: classnames(defaultSolidClasses, kellyGreenSolidClasses, styles.buttonHoverKellyGreen, styles.outlineKelleyGreen),
  },
  'harlequin': {
    outline: classnames(
      defaultOutlineClasses,
      harlequinOutlineClasses, 
      styles.buttonHoverHarlequin,
      styles.outlineHarlequin
    ),
    solid: classnames(defaultSolidClasses, harlequinSolidClasses, styles.buttonHoverHarlequin, styles.outlineHarlequin),
  },
  'springgreen': {
    outline: classnames(
      defaultOutlineClasses,
      harlequinOutlineClasses, 
      styles.buttonHoverHarlequin,
      styles.outlineHarlequin
    ),
    solid: classnames(defaultSolidClasses, springgreenSolidClasses, styles.buttonHoverHarlequin, styles.outlineHarlequin),
  },
  'supernova': {
    outline: classnames(
      defaultOutlineClasses,
      supernovaOutlineClasses,
      styles.buttonHoverSupernova,
      styles.outlineSupernova
    ),
    solid: classnames(defaultSolidClasses, supernovaSolidClasses, styles.outlineSupernova, styles.buttonHoverSupernova),
  }
}