import React,{
    useEffect,
    useState
} from 'react';
import ViewModal from 'react-modal';

const Modal = ({modalIsOpen,closeModal,disable,children}) => {

    const[widthScreen,setWidthScreen] = useState(window.innerWidth);

    const reloadWidthScreen = window.addEventListener('resize',() => 
        setWidthScreen(window.innerWidth)
    );

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius:'25px',
          width: widthScreen < 767  ? '340px' : '415px',
          background:  disable ? '#000': '#fff',
          opacity:  disable ? '.8' : '1'
        },
    };
    
    useEffect(() => {
        reloadWidthScreen
    },[ widthScreen]);

    useEffect(() => {
        document.body.style.overflow = !modalIsOpen  ? 'visible' : 'hidden';
    }, [modalIsOpen]);
    
    return(
        <ViewModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Modal Lead"
        >
            {children}
        </ViewModal>
    )
}

export default Modal