import * as React from "react"

function FacebookBlue(props) {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>icon/redesocial/facebook</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="360px---Empresa-digital---Produtos-default" transform="translate(-57.000000, -2269.000000)" fill="#4267B2">
          <g id="Group-5" transform="translate(0.000000, 1889.000000)">
            <g id="icon/redesocial/facebook" transform="translate(57.000000, 380.000000)">
              <path d="M30.2340309,-5.24590164e-05 L1.76596914,-5.24590164e-05 C0.790557526,-5.24590164e-05 0,0.790347541 0,1.76581421 L0,30.2335475 C0,31.2090142 0.790557526,31.9999475 1.76596914,31.9999475 L17.0923438,31.9999475 L17.0923438,19.6079475 L12.9222061,19.6079475 L12.9222061,14.7786142 L17.0923438,14.7786142 L17.0923438,11.2170142 C17.0923438,7.08368087 19.6169072,4.83301421 23.3038672,4.83301421 C25.0698364,4.83301421 26.5880904,4.96474754 27.0302485,5.02341421 L27.0302485,9.34288087 L24.4731892,9.34394754 C22.4680284,9.34394754 22.079675,10.2970142 22.079675,11.6948809 L22.079675,14.7786142 L26.8619088,14.7786142 L26.2391583,19.6079475 L22.079675,19.6079475 L22.079675,31.9999475 L30.2340309,31.9999475 C31.2094425,31.9999475 32,31.2090142 32,30.2335475 L32,1.76581421 C32,0.790347541 31.2094425,-5.24590164e-05 30.2340309,-5.24590164e-05" id="Mask"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FacebookBlue
