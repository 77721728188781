import * as React from "react"

function SvgVoltarIcon(props) {
  return (
    <svg width={13} height={23} viewBox="0 0 13 23" {...props}>
      <path
        d="M0 11.5c0-.405.154-.81.463-1.118l9.84-9.838a1.58 1.58 0 112.234 2.235L3.817 11.5l8.72 8.721a1.58 1.58 0 11-2.235 2.235l-9.84-9.838A1.576 1.576 0 010 11.5"
        fill="#1E333B"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgVoltarIcon
