import * as React from "react"

function LinkedinBlue(props) {
  return (
    <svg width="32px" height="33px" viewBox="0 0 32 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <title>icon/redesocial/linkeding</title>
      <defs>
        <path d="M27.2692019,28.1204478 L22.5232923,28.1204478 L22.5232923,20.4624017 C22.5232923,18.6354343 22.4930693,16.2859524 20.057446,16.2859524 C17.5871552,16.2859524 17.2111448,18.2770077 17.2111448,20.3313148 L17.2111448,28.1204478 L12.4705686,28.1204478 L12.4705686,12.371677 L17.0200283,12.371677 L17.0200283,14.5249868 L17.084919,14.5249868 C17.7178255,13.2865357 19.266313,11.9811661 21.5757104,11.9811661 C26.3811773,11.9811661 27.2692019,15.2418401 27.2692019,19.4833745 L27.2692019,28.1204478 Z M7.11841996,10.2202006 C5.59393316,10.2202006 4.36456568,8.94874858 4.36456568,7.38120503 C4.36456568,5.81457818 5.59393316,4.5431262 7.11841996,4.5431262 C8.63668435,4.5431262 9.86960749,5.81457818 9.86960749,7.38120503 C9.86960749,8.94874858 8.63668435,10.2202006 7.11841996,10.2202006 L7.11841996,10.2202006 Z M4.74146504,28.1204478 L9.49270813,28.1204478 L9.49270813,12.371677 L4.74146504,12.371677 L4.74146504,28.1204478 Z M29.6328231,0 L2.36095447,0 C1.05869607,0 0,1.06519626 0,2.37973277 L0,30.6202672 C0,31.9338871 1.05869607,33.0009167 2.36095447,33.0009167 L29.6328231,33.0009167 C30.9377483,33.0009167 32,31.9338871 32,30.6202672 L32,2.37973277 C32,1.06519626 30.9377483,0 29.6328231,0 L29.6328231,0 Z" id="path-2"></path>
      </defs>
      <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="360px---Empresa-digital---Produtos-default" transform="translate(-161.000000, -2269.000000)">
          <g id="Group-6" transform="translate(0.000000, 1889.000000)">
            <g id="icon/redesocial/linkeding" transform="translate(161.000000, 380.000000)">
              <mask id="mask-3" fill="white">
                <use xlinkHref="#path-2"></use>
              </mask>
              <use id="Mask" fill="#00A750" xlinkHref="#path-2"></use>
              <g id="Group" mask="url(#mask-3)" fill="#0077B5">
                <g id="color/verde">
                  <rect id="Rectangle" x="0" y="0" width="32" height="33"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LinkedinBlue
