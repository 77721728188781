import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL_PORTAL,
});

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('tokenPortal');
  return config;
}, null);

axiosInstance.interceptors.response.use(null, error => {
  const {
    config,
    response: { status },
  } = error;

  if (status !== 401) {
    // Rejeita a promise com o erro original
    return Promise.reject(error);
  }

  return axiosInstance.post('/ip-token').then(res => {
    window.localStorage.setItem('tokenPortal', `Bearer ${res.data.access_token}`);
    return axiosInstance(config);
  });
});

export default axiosInstance;

