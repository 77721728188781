/* eslint-disable no-undef */
import axios from "axios";
import ax from './axios-instance';
import GoogleSheets from './GoogleSheets';

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL_SCRIPT_SPREADSHEET,
});

const axiosPublicIpInstance = axios.create({
  baseURL: "https://api.ipify.org",
});

const Leads = {
  leadsBot: params => axiosInstance.get(process.env.SPREADSHEET_LINK, { params }),
  getPublicIP: () => axiosPublicIpInstance.get("?format=json"),
  postLeadInSheet: params => axiosInstance.get(process.env.SPREADSHEET_LINK_LEADS_DAY, { params }),
  postLeadMPE: params => GoogleSheets.append('AKfycbx55AlHA-2NN1JnHEDZ1PnOM7ib9Kfj6Ajizs2vL94AWkjB6AiN6s833CQzbvFTUiXP', params),
  leadsReceptivasCorporativo: params => ax.post('leads/leads-receptivas-corporativo', params),
  getStates:(data) => axios.get(`${process.env.BASE_URL_IBGE}${data}/distritos`),
  createToken: () => ax.post(`${process.env.BASE_URL_PORTAL}/ip-token`),
  leadsWeCallYouRetailQuerySegmentClient: params => ax.get(`leads/leads-we-call-you-retail-query-segment-client?cpf_cnpj=${params}`),
  createLeadAlgarCRM: params => ax.post('leads',params)
}

export default Leads;
