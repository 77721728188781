import * as React from "react"

function SvgProductivity(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" {...props}>
      <defs>
        <style>
          {
            ".productivity_svg__prefix__cls-2{fill:#fff}.productivity_svg__prefix__cls-3{fill:#00a750}"
          }
        </style>
      </defs>
      <g id="productivity_svg__prefix__Produtividade" opacity={0.9}>
        <path
          id="productivity_svg__prefix__Path_145"
          d="M98.424 14.135l-2.15-1.24a7.65 7.65 0 000-1.789l2.15-1.25a.8.8 0 00.3-1.06l-3.2-5.6a.812.812 0 00-.49-.38.764.764 0 00-.61.08l-2.14 1.27a9.616 9.616 0 00-1.55-.9V.8a.8.8 0 00-.8-.8h-6.4a.8.8 0 00-.8.8v2.479a9.656 9.656 0 00-1.6.9l-2.1-1.24a.8.8 0 00-1.09.26l-1.42 2.479a12.025 12.025 0 00-1.7-.08A18.2 18.2 0 0065.811 8l-.18.06a.526.526 0 00-.14.12 18.558 18.558 0 00-6.509 6.518.9.9 0 00-.09.1.88.88 0 00-.05.15 18.2 18.2 0 00-2.359 7.7.8.8 0 001.6.12 16.652 16.652 0 011.84-6.458l1.35.8a.756.756 0 00.39.1.81.81 0 00.7-.4.763.763 0 00.08-.61.834.834 0 00-.37-.49l-1.34-.8a17.015 17.015 0 015.029-5.038l.8 1.35a.8.8 0 00.49.37.862.862 0 00.61-.08.811.811 0 00.3-1.09l-.8-1.36a16.647 16.647 0 0115.377 0l-.8 1.36a.764.764 0 00-.08.61.812.812 0 00.38.49.764.764 0 00.61.08.8.8 0 00.49-.37l.8-1.36a16.883 16.883 0 015.049 5.048l-1.36.8a.789.789 0 00-.31 1.07.8.8 0 00.7.4.868.868 0 00.39-.1l1.36-.8a16.645 16.645 0 011.88 6.9h-1.6a.8.8 0 100 1.6h1.6a16.746 16.746 0 01-1.84 6.887l-1.36-.8a.8.8 0 00-1.09.3.811.811 0 00.29 1.12l1.35.8a17.015 17.015 0 01-5.039 5.028l-.8-1.34a.806.806 0 00-1.4.8l.8 1.35a16.56 16.56 0 01-6.459 1.83.8.8 0 10.06 1.6h.06a18.222 18.222 0 007.618-2.379.6.6 0 00.21-.08c.03-.03.07-.06.1-.09a18.581 18.581 0 006.489-6.458.845.845 0 00.15-.18.635.635 0 00.06-.18 18.17 18.17 0 00-.04-18.153V14.8a18.536 18.536 0 00-6.669-6.668h-.05a18.251 18.251 0 00-5.829-2.209l.7-1.2 1.91 1.1a.82.82 0 00.9-.06 8.082 8.082 0 012.09-1.21.789.789 0 00.51-.74V1.6h4.8v2.209a.794.794 0 00.5.74 7.806 7.806 0 012.09 1.21.8.8 0 00.9.06l1.91-1.1 2.4 4.158-1.91 1.1a.788.788 0 00-.39.8 6.907 6.907 0 010 2.4.8.8 0 00.39.8l1.91 1.1-2.14 3.719a.763.763 0 00-.08.61.812.812 0 00.38.49.756.756 0 00.39.1.81.81 0 00.7-.4l2.509-4.4a.791.791 0 00-.304-1.061z"
          className="productivity_svg__prefix__cls-2"
          data-name="Path 145"
          transform="translate(-50.817)"
        />
        <path
          id="productivity_svg__prefix__Path_146"
          d="M351 72.8a.8.8 0 00.8.8 3.205 3.205 0 013.209 3.209.8.8 0 101.6 0A4.814 4.814 0 00351.8 72a.8.8 0 00-.8.8z"
          className="productivity_svg__prefix__cls-2"
          data-name="Path 146"
          transform="translate(-315.907 -64.802)"
        />
        <path
          id="productivity_svg__prefix__Path_147"
          d="M94.724 299.209a1.207 1.207 0 011.2 1.2.8.8 0 101.6 0 2.816 2.816 0 00-2.006-2.677v-.932a.8.8 0 00-1.6 0v.933a2.8 2.8 0 00.8 5.485 1.2 1.2 0 11-1.2 1.2.8.8 0 10-1.6 0 2.816 2.816 0 002.006 2.677v.933a.8.8 0 001.6 0v-.928a2.8 2.8 0 00-.8-5.485 1.2 1.2 0 010-2.407z"
          className="productivity_svg__prefix__cls-3"
          data-name="Path 147"
          transform="translate(-82.718 -266.451)"
        />
        <path
          id="productivity_svg__prefix__Path_148"
          d="M40.824 272a8.824 8.824 0 108.824 8.824A8.824 8.824 0 0040.824 272zm0 16.044a7.22 7.22 0 117.22-7.22 7.215 7.215 0 01-7.22 7.22z"
          className="productivity_svg__prefix__cls-2"
          data-name="Path 148"
          transform="translate(-28.791 -244.857)"
        />
        <path
          id="productivity_svg__prefix__Path_149"
          d="M12.033 240a12.033 12.033 0 1012.033 12.033A12.03 12.03 0 0012.033 240zm0 22.462a10.429 10.429 0 1110.429-10.429 10.434 10.434 0 01-10.429 10.429z"
          className="productivity_svg__prefix__cls-2"
          data-name="Path 149"
          transform="translate(0 -216.066)"
        />
        <path
          id="productivity_svg__prefix__Path_150"
          d="M200.126 114.962l-.451.8a.817.817 0 000 .8.8.8 0 00.7.4h.05l5.776-.371a.8.8 0 00.662-1.153l-2.567-5.194a.81.81 0 00-.692-.441.792.792 0 00-.722.4l-.451.8-2.507-1.063a3.226 3.226 0 00-1.263-2.126l.451-5.505h1.665a.821.821 0 00.732-.461.8.8 0 00-.11-.852l-4.011-4.813a.816.816 0 00-.622-.291.832.832 0 00-.622.291l-4.01 4.817a.8.8 0 00.622 1.314h1.665l.451 5.435a3.207 3.207 0 003.089 5.565zm2.346-2.256a.805.805 0 001-.341l.06-.1 1.364 2.768-3.068.2.05-.1a.8.8 0 00-.211-1.033l-2.407-1.795a3.006 3.006 0 00.451-.8zm-6.508-11.241a.8.8 0 00-.8-.742h-.692l2.3-2.758 2.3 2.758h-.692a.8.8 0 00-.8.742l-.471 5.676h-.662zm-.8 8.884a1.6 1.6 0 01.913-1.444 1.627 1.627 0 011.334 0 1.607 1.607 0 01.963 1.444v.09a1.606 1.606 0 01-3.209-.09z"
          className="productivity_svg__prefix__cls-3"
          data-name="Path 150"
          transform="translate(-172.774 -86.331)"
        />
      </g>
    </svg>
  )
}

export default SvgProductivity
