import * as React from "react"

function Star(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path fill="#39FF14" d="M45.915 16.895l-14.9-1.386-5.918-13.785c-.415-.965-1.78-.965-2.194 0l-5.918 13.785-14.9 1.386c-1.043.097-1.465 1.399-.677 2.092l11.241 9.906-3.29 14.643c-.23 1.025.874 1.83 1.775 1.293L24 37.166l12.866 7.663c.9.536 2.005-.268 1.775-1.293l-3.29-14.642 11.241-9.906c.788-.694.366-1.996-.677-2.093z" transform="translate(-19 -1076) translate(19 1076)" />
            <path fill="#37D719" d="M25.076 1.724c-.414-.965-1.777-.965-2.191 0L16.97 15.509 2.084 16.895c-1.042.097-1.463 1.399-.677 2.092l11.233 9.906-3.288 14.643c-.23 1.025.873 1.83 1.773 1.293l2.87-1.71C14.39 26.707 21.986 15.15 28 8.54l-2.924-6.816z" transform="translate(-19 -1076) translate(19 1076)" />
            <path fill="#FFF" d="M47.905 17.771c-.237-.732-.857-1.23-1.62-1.302l-14.466-1.35-2.867-6.697c-.152-.354-.56-.517-.91-.365-.352.152-.515.562-.363.916l3.03 7.078c.1.234.32.395.573.418l14.876 1.388c.295.028.398.25.428.345.03.094.078.335-.145.532l-11.224 9.918c-.19.168-.274.428-.219.677l3.285 14.66c.065.29-.113.457-.193.516-.08.058-.293.178-.548.025l-12.846-7.672c-.218-.13-.49-.13-.708 0L11.142 44.53c-.255.153-.468.033-.548-.025-.08-.059-.259-.226-.194-.517l1.696-7.567c.084-.375-.15-.749-.524-.833-.374-.085-.744.151-.828.527l-1.696 7.567c-.168.75.112 1.498.731 1.95.62.453 1.413.49 2.07.097l12.493-7.46 12.492 7.46c.303.181.635.271.965.271.386 0 .771-.123 1.105-.367.62-.453.9-1.2.731-1.951l-3.194-14.256 10.915-9.644c.575-.508.785-1.279.549-2.01zM10.708 33.135c-.084.382.15.761.522.847.052.012.103.018.153.018.317 0 .602-.224.674-.553l.92-4.182c.056-.253-.028-.517-.218-.689L1.556 18.491c-.222-.2-.175-.446-.145-.542.03-.096.133-.322.428-.35l14.848-1.411c.252-.024.47-.188.571-.426l5.898-14.036c.117-.278.359-.308.457-.308.099 0 .34.03.458.308l1.602 3.812c.151.36.558.526.909.371.35-.155.513-.572.361-.931l-1.601-3.812C25.039.446 24.377 0 23.613 0c-.763 0-1.426.447-1.728 1.166L16.15 14.815 1.71 16.187c-.76.072-1.38.58-1.616 1.324-.236.745-.026 1.528.548 2.045l10.894 9.808-.83 3.77z" transform="translate(-19 -1076) translate(19 1076)" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Star
