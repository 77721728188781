import {
  Logo,
  Menu,
  Delivery,
  Cleansing,
  LogoWhite,
  Facebook,
  FacebookIcon,
  Instagram,
  Linkedin,
  Youtube,
  Calendar,
  Chart,
  Cash,
  Money,
  Courses,
  Marketing,
  PriceTag,
  SmallStore,
  MediumStore,
  LargeStore,
  Investiment,
  LargeArea,
  LargeEmployee,
  MediumArea,
  MediumEmployee,
  SmallArea,
  SmallEmployee,
  Support,
  CompleteAdvisory,
  EnhancedSecurity,
  TotalSecurity,
  Shield,
  Productivity,
  Economy,
  Communication,
  CollaborativeWork,
  Protection,
  Performance,
  FlexibleConsumption,
  Flexibility,
  EasyAccess,
  ChipElectronics,
  LowCost,
  Limitless,
  FullFlexibility,
  LogoAlgarWhite,
  Whatsapp,
  Chat,
  WhatsappWhite,
  FacebookBlue,
  InstagramNew,
  InstagramIcon,
  LinkedinBlue,
  LinkedinIcon,
  Twitter,
  TwitterIcon,
  YoutubeRed,
  YoutubeIcon,
  BlogAlgar,
  ArrowRight,
  ArrowLeft,
  CloseGreen,
  Star,
  StarPlus,
  Fechar,
  Voltar,
  LogoColor
} from './components'

export const Icons = {
  Logo,
  Menu,
  Delivery,
  Cleansing,
  LogoWhite,
  Facebook,
  FacebookIcon,
  Instagram,
  Linkedin,
  LinkedinIcon,
  Youtube,
  Calendar,
  Chart,
  Cash,
  Money,
  Courses,
  Marketing,
  PriceTag,
  SmallStore,
  MediumStore,
  LargeStore,
  Investiment,
  LargeArea,
  LargeEmployee,
  MediumArea,
  MediumEmployee,
  SmallArea,
  SmallEmployee,
  Support,
  CompleteAdvisory,
  EnhancedSecurity,
  TotalSecurity,
  Shield,
  Productivity,
  Economy,
  Communication,
  CollaborativeWork,
  Protection,
  Performance,
  FlexibleConsumption,
  Flexibility,
  EasyAccess,
  ChipElectronics,
  LowCost,
  Limitless,
  FullFlexibility,
  LogoAlgarWhite,
  Whatsapp,
  Chat,
  WhatsappWhite,
  FacebookBlue,
  InstagramNew,
  InstagramIcon,
  LinkedinBlue,
  Twitter,
  TwitterIcon,
  YoutubeRed,
  YoutubeIcon,
  BlogAlgar,
  ArrowRight,
  ArrowLeft,
  CloseGreen,
  Star,
  StarPlus,
  Fechar,
  Voltar,
  LogoColor
};
